import {useTranslation} from "react-i18next";
import React, {ChangeEvent, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {clearOrganization, setOrganization} from "../../../redux/slices/organizations";
import {
    createOrganization,
    getAllOrganizations,
    getOneOrganization,
    updateOrganization
} from "../../../redux/services/organizationService";
import {OrganizationCreateUpdateDTO} from "../../../services/openapi";
import {toast} from "react-toastify";
import {SVG} from "../../core/SVG";
import {EditIcon} from "../../Icons";
import {DatePicker, DatePickerProps, Modal, Select, Spin} from "antd";
import InputGroup from "../../core/InputGroup";
import moment from "moment/moment";

type Props = {
    id?: any;
};
const CreateUpdateOrganization = ({ id }: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { loading, organization } = useAppSelector(
        (state) => state.organization
    );
    // const { businesses } = useAppSelector((state) => state.business);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setOrganization({
                name: event.target.name,
                value: event.target.value,
            })
        );
    };
    const onChangeDatePicker: DatePickerProps["onChange"] = (_, dateString) => {
        dispatch(
          setOrganization({
              name: 'expireDate',
              value: dateString,
          })
        );
    };

    const changeSelectHandler = (
      event?: any,
      name?: string,
    ) => {
        dispatch(setOrganization({
            name,
            value: event
        }))
    }

    // const changeSelectHandler = (e: any, nameID: string, name: string) => {
    //     dispatch(
    //         setOrganization({
    //             name: nameID,
    //             value: e ? e.value : undefined
    //         })
    //     );
    //     dispatch(
    //         setOrganization({
    //             name: name,
    //             value: e ? e.label : undefined
    //         })
    //     );
    // };

    const handleOpen = () => {
        setOpen(true);
        // dispatch(getAllBusinessDirection({size:10000}));
        if (id) {
            dispatch(getOneOrganization(id));
        }
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(clearOrganization());
    };

    const handleSubmit = async () => {
        try {
            let res: any = await dispatch(
                id
                    ? updateOrganization(organization as OrganizationCreateUpdateDTO)
                    : createOrganization(organization as OrganizationCreateUpdateDTO)
            );
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    handleClose();
                    dispatch(getAllOrganizations({size: 25, page:0}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const tariffTypes = [
        {
            name: "BRONZE",
            code: "BRONZE",
        },
        {
            name: "SILVER",
            code: "SILVER",
        },
        {
            name: "GOLD",
            code: "GOLD",
        },
        {
            name: "PLATINUM",
            code: "PLATINUM",
        },
    ];

    return (
        <>
            <button
                className={`btn ${
                    id
                        ? "btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
                        : "btn-sm btn-primary"
                }`}
                onClick={handleOpen}
            >
                {id ? <SVG icon={<EditIcon />} className="svg-icon-2" /> : t("create")}
            </button>
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        {t(`${id ? "updateOrganization" : "createOrganization"}`)}
                        {loading ? <Spin className="d-flex ms-2" size="small" /> : ""}
                    </div>
                }
                open={open}
                onCancel={handleClose}
                cancelText={t("cancel")}
                onOk={handleSubmit}
                okText={t("save")}
                width={1000}
            >
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label={t("orgName")}
                            type="text"
                            name="name"
                            required
                            value={organization.name}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <div className="col">
                            <InputGroup
                                label={t("tin")}
                                type="text"
                                name="inn"
                                value={organization.inn}
                                onChange={changeHandler}
                            />
                        </div>
                        {/*<InputGroup*/}
                        {/*    label={t("orgCode")}*/}
                        {/*    type="text"*/}
                        {/*    name="code"*/}
                        {/*    required*/}
                        {/*    value={organization.code}*/}
                        {/*    onChange={changeHandler}*/}
                        {/*/>*/}
                    </div>
                </div>
                {/*<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">*/}
                {/*    <div className="col">*/}
                {/*        <label className="fs-6 fw-semibold form-label mt-3">*/}
                {/*            <span className="required">{t("activity")}</span>*/}
                {/*        </label>*/}
                        {/*<Select*/}
                        {/*    showSearch*/}
                        {/*    optionLabelProp="label"*/}
                        {/*    optionFilterProp="children"*/}
                        {/*    placeholder={t('choose')}*/}
                        {/*    className="w-100 form-control form-select-solid p-0"*/}
                        {/*    allowClear*/}
                        {/*    labelInValue*/}
                        {/*    value={{*/}
                        {/*        value: organization.businessDirectionId,*/}
                        {/*        label: organization.businessDirectionName*/}
                        {/*    }}*/}
                        {/*    filterOption={(input, option) =>*/}
                        {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                        {/*    }*/}
                        {/*    onChange={(e) => changeSelectHandler(e, 'businessDirectionId', 'businessDirectionName')}*/}
                        {/*    options={businesses.map((el) => {*/}
                        {/*        return(*/}
                        {/*            {*/}
                        {/*                value: el.id,*/}
                        {/*                label: el.name*/}
                        {/*            }*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*/>*/}
                    {/*</div>*/}
                    {/*<div className="col">*/}
                    {/*    <InputGroup*/}
                    {/*        label={t("tin")}*/}
                    {/*        type="text"*/}
                    {/*        name="inn"*/}
                    {/*        value={organization.inn}*/}
                    {/*        onChange={changeHandler}*/}
                    {/*    />*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">*/}
                {/*  <div className="col">*/}
                {/*    <InputGroup label={t("mfo")} type="text" name="mfo" />*/}
                {/*  </div>*/}
                {/*  <div className="col">*/}
                {/*    <InputGroup*/}
                {/*      label={t("checkingAccount")}*/}
                {/*      type="text"*/}
                {/*      name="checkingAccount"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                          label={t("activity")}
                          type="text"
                          name="activity"
                          value={organization.activity}
                          onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                          label={t("address")}
                          type="text"
                          name="address"
                          value={organization.address}
                          onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                          label={t("email")}
                          type="email"
                          name="email"
                          value={organization.email}
                          onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                          label={t("phone")}
                          type="text"
                          name="phone"
                          value={organization.phone}
                          onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">{t("plan")}</span>
                        </label>
                        <Select
                          placeholder={t("choose")}
                          className="w-100 form-control form-select-solid p-0"
                          allowClear
                          value={organization.tariffType}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e) => changeSelectHandler(e, "tariffType")}
                          options={tariffTypes.map((el) => {
                              return {
                                  value: el.code,
                                  label: el.name,
                              };
                          })}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                          label={t("requestsCount")}
                          type="number"
                          name="attemptCount"
                          value={organization.attemptCount}
                          onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <div className="fv-row mb-1">
                            <label className="fs-6 fw-semibold form-label mt-3">
                                <span className="required">{t("expiredDate")}</span>
                            </label>
                            <DatePicker
                              onChange={onChangeDatePicker}
                              name="expireDate"
                              className="form-control form-control-solid h-40px"
                              format="DD.MM.YYYY"
                              placeholder={t("expiredDate")}
                              value={
                                  organization?.expireDate && moment(organization?.expireDate, "DD.MM.YYYY").isValid()
                                    ? moment(organization?.expireDate, "DD.MM.YYYY")
                                    : moment()
                              }
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateUpdateOrganization;
