import styled from "styled-components";

export const LoginWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .front{
    position: absolute;
  }
  .ant-row {
    width: 100%;
    height: 100%;
  }
  .left {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right{
    position: absolute;
  }
  .form {
    padding: 20px;
    min-width: 350px;
    max-width: 400px;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(8px);
    border-radius: 20px;
    overflow: hidden; 
  }
  @media screen and (max-width: 576px) {
    .form{
      min-width: 300px;
      max-width: 320px;
    }
  }
`;
