import {
  CheckIcon,
  OrganizationsIcon,
  SettingsIcon,
  UserIcon,
  DashboardIcon, ActivityIcon, CustomersIcon, SecurityIcon, CheckedSuppliersIcon,
} from "../Icons";

export const MenuData = [
  // {
  //   name: "dashboard",
  //   icon: <DashboardIcon />,
  // },
  {
    name: "profile",
    link: "/profile",
    icon: <UserIcon />,
    permission: "PROFILE_VIEW",
  },
  {
    name: "users",
    link: "/users",
    icon: <CustomersIcon />,
    permission: "USER_VIEW",
  },
  {
    name: "organizations",
    link: "/organizations",
    icon: <OrganizationsIcon />,
    permission: "ORGANIZATION_VIEW",
  },
  {
    name: "check",
    link: "/check",
    icon: <CheckIcon />,
    permission: "CHECK_SUPPLIER",
  },
  {
    name: "residents",
    link: "/residents",
    icon: <CheckedSuppliersIcon />,
    permission: "CHECK_SUPPLIER",
  },
  {
    name: "dubiousOrganizationsMenu",
    link: "/dubious-organizations",
    icon: <SecurityIcon />,
    permission: "CHECKED_SUPPLIER",
  },
  {
    name: "unscrupulousPerformer",
    link: "/unscrupulous-performer",
    icon: <DashboardIcon />,
    permission: "CHECKED_SUPPLIER",
  },
  {
    name: "allServices",
    link: "/services",
    icon: <ActivityIcon />,
    permission: "CHECKED_SUPPLIER",
  },
  {
    name: "settings",
    link: "/sub",
    icon: <SettingsIcon />,
    permission: "SETTING_VIEW",
    tree: [
      {
        name: "messages",
        link: "/messages",
        permission: "MESSAGE_VIEW",
      },
      {
        name: "roles",
        link: "/roles",
        permission: "ROLE_VIEW",
      },
      {
        name: "permissions",
        link: "/permissions",
        permission: "PERMISSION_VIEW",
      },
      {
        name: "access",
        link: "/access",
        permission: "GET_ACCESS",
      },
      {
        name: "egovError",
        link: "/egov/error",
        permission: "GET_ACCESS",
      },
      {
        name: "egovRequest",
        link: "/egov/request",
        permission: "GET_ACCESS",
      },
    ],
  },
];
