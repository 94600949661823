import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getStorage } from "../helpers/localStorage";
import EN from "./en.json";
import RU from "./ru.json";
import UZ from "./uz.json";
import UZK from "./uzk.json";

i18n.use(initReactI18next).init({
  resources: {
    EN: { translation: EN },
    RU: { translation: RU },
    UZ: { translation: UZ },
    UZK: { translation: UZK },
  },
  lng: getStorage("_lang") || "RU",
  fallbackLng: getStorage("_lang") || "RU",
  interpolation: { escapeValue: false },
});

export default i18n;

// Ў ў Қ қ Ғ ғ Ҳ ҳ
