import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFilterRole } from "../../models";
import {
  RoleControllerService,
  RoleCreateUpdateDTO,
} from "../../services/openapi";
import { toast } from "react-toastify";

export const getAllRoles = createAsyncThunk(
  "role/get-all",
  async (args: IFilterRole) => {
    try {
      const response = await RoleControllerService.getAllUsingGet5(
        (args.page = args.page || 0),
        (args.size = args.size || 25),
        args.id,
        args.name
      );
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const createRole = createAsyncThunk(
  "role/create",
  async (data: RoleCreateUpdateDTO) => {
    try {
      return await RoleControllerService.createUsingPost3(data);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const getOneRole = createAsyncThunk(
  "role/get-one",
  async (id: number) => {
    try {
      const response = await RoleControllerService.getUsingGet4(id);
      return response.data;
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const updateRole = createAsyncThunk(
  "role/update",
  async (data: RoleCreateUpdateDTO) => {
    try {
      return await RoleControllerService.updateUsingPut3(data);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);

export const deleteRole = createAsyncThunk(
  "role/delete",
  async (id: number) => {
    try {
      return await RoleControllerService.deleteUsingDelete4(id);
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    }
  }
);
