import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import Toolbar from "../../Toolbar";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDubiousOrganizations } from "../../../redux/services/supplierService";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import {CheckedSupplierControllerService, ObnalSupplierDto} from "../../../services/openapi";
import {PaginationProps, Spin} from "antd";
import CardBody from "../../core/CardBody";
import CustomTable from "../../core/CustomTable";
import Input from "../../core/Input";
import { IFilterDubiousOrganizations } from "../../../models";
import { SVG } from "../../core/SVG";
import { DeleteIcon, SearchTableIcon } from "../../Icons";
import { fieldCheck } from "../../../helpers/fieldCheck";
import {toast} from "react-toastify";

const DubiousOrganizations = () => {
  const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(25);
  const [upload, setUpload] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterDubiousOrganizations>({
      city: "",
      inn: "",
      organizationName: "",
      registerDate: "",
      founderName: "",
    });
  const ref = React.createRef<HTMLInputElement>();
    const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
      setPage(pageNumber - 1);
    };
    const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
      current,
      pageSize
    ) => {
      setPage(current);
      setSize(pageSize);
    };
    const dispatch = useAppDispatch();
  const { dubiousOrganizations, dubiousTotalCount, dbLoader, error } =
    useAppSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(getDubiousOrganizations({ page, size }));
  }, [dispatch, page, size]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setFilter((filter) => ({ ...filter, [name]: value }));
  };

  const handleFilter = async () => {
    await dispatch(
      getDubiousOrganizations({
        page: 0,
        size,
        ...fieldCheck(filter),
      })
    );
  };
  const handleClearFilter = async () => {
    setFilter({
      city: "",
      inn: "",
      organizationName: "",
      registerDate: "",
      founderName: "",
    });
    await dispatch(
      getDubiousOrganizations({
        page: 0,
        size,
      })
    );
  };

  const columns: (
    | ColumnGroupType<ObnalSupplierDto>
    | ColumnType<ObnalSupplierDto>
  )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = dubiousOrganizations.indexOf(row);
        return page * size + index + 1;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("region")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="city"
              value={filter.city}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "city",
      key: "city",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("tin")}</div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="inn"
              value={filter.inn}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("organization")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="organizationName"
              value={filter.organizationName}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("registerDate")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="registerDate"
              value={filter.registerDate}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "registerDate",
      key: "registerDate",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("founderName")} </div>
          <div className="fv-row mb-4 w-150px min-w-100 d-flex">
            <Input
              type="text"
              name="founderName"
              value={filter.founderName}
              onChange={handleChangeFilter}
            />
            <div className="fv-row mb-4">
              <div
                onClick={handleClearFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<DeleteIcon />} className="svg-icon-1" />
              </div>
            </div>
            <div className="fv-row mb-4">
              <div
                onClick={handleFilter}
                className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
              >
                <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
              </div>
            </div>
          </div>
        </div>
      ),
      dataIndex: "founderName",
      key: "founderName",
    },
  ];

  const data = useMemo(() => dubiousOrganizations, [dubiousOrganizations]);

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setUpload(true)
    if (!event.target.files) return;
    try {
      const file = event.target.files[0];
      let blob = new Blob([file], { type: "application/xls" });
      const res = await CheckedSupplierControllerService.uploadObnalSupplierUsingPost(blob)
      toast(res.message, {
        type: "success",
      });
    } catch (error: any) {
      toast(error?.body?.errors[0]?.errorMsg || error.message, {
        type: "error",
      });
    } finally {
      setUpload(false);
    }
  }

  return (
    <>
      <Toolbar title={t("dubiousOrganizations")} />
      <CardBody
        children={
        <>
          <div>
            <div className="d-flex align-items-end flex-wrap">
              <div className="fv-row d-flex flex-column align-items-start position-relative">
                <label className="fs-6 fw-semibold form-label mt-3">
                  <span className="required">{t("file")}</span>
                </label>
                <label
                    htmlFor="file"
                    className="btn btn-secondary w-200px fs-6 fw-semibold form-label mb-4 d-flex flex-column"
                >
                  {t("chooseFile")}
                </label>
                {upload ? (
                    <Spin className="d-flex position-absolute ms-2" style={{
                      left: '100%',
                      bottom: '30px',
                    }} size="small"/>
                ) : (
                    ""
                )}
                <input
                    type="file"
                    name="file"
                    ref={ref}
                    className="d-none"
                    id="file"
                    onChange={uploadFile}
                />
              </div>
            </div>
          </div>
          <CustomTable
              columns={columns}
              error={error}
              data={data}
              loading={dbLoader}
              page={page}
              pageSize={size}
              className="table-striped-rows"
              paginationShowChange={onShowSizeChange}
              paginationChange={onChangePagination}
              totalCount={dubiousTotalCount}
          />
        </>
        }
      />
    </>
  );
};

export default DubiousOrganizations;
