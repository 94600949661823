import {MessageCreateUpdateDTO, MessageDTO} from "../../services/openapi";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createMessage, getAllMessages, getOneMessage, updateMessage} from "../services/messageService";

interface MessageState {
    messages: MessageDTO[];
    message: MessageCreateUpdateDTO;
    loading: boolean;
    status: string;
    error: string;
    response: any;
    totalCount: any
}

const initialState: MessageState = {
    messages: [],
    message: {},
    loading: false,
    status: "",
    error: "",
    response: {},
    totalCount: 0
};

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessage: (
            state,
            action: PayloadAction<{ name: any; value: string }>
        ) => {
            state.message = {
                ...state.message,
                [action.payload.name]: action.payload.value,
            };
        },
        clearMessage: (state) => {
            state.message = {
                key: "",
                textRu: "",
                textEn: "",
                textUz: ""
            };
        },
    },
    extraReducers: {
        [getAllMessages.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllMessages.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.messages = payload.content;
            state.totalCount = payload.totalElements
            state.loading = false;
        },
        [getAllMessages.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [createMessage.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [createMessage.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [createMessage.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getOneMessage.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getOneMessage.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.message = payload;
            state.loading = false;
        },
        [getOneMessage.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [updateMessage.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [updateMessage.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [updateMessage.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});

export const { setMessage, clearMessage } = messageSlice.actions;

export default messageSlice.reducer;