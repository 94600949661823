import {createAsyncThunk} from "@reduxjs/toolkit";
import { IResidentsFilter} from "../../models";
import {toast} from "react-toastify";
import {RemoteControllerService} from "../../services/openapi";

export const getAllResidents = createAsyncThunk(
    "resident/get-all",
    async (args: IResidentsFilter) => {
        try {
            const response = await RemoteControllerService.getAllResidentUsingGet(
                args.inn,
                args.name, (args.page = args.page || 0),
                (args.size = args.size || 25),
            );
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);