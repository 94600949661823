import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {clearRole, setRole} from "../../../redux/slices/role";
import {createRole, getAllRoles, getOneRole, updateRole} from "../../../redux/services/roleService";
import {RoleCreateUpdateDTO} from "../../../services/openapi";
import {toast} from "react-toastify";
import {SVG} from "../../core/SVG";
import {EditIcon} from "../../Icons";
import {Modal, Spin} from "antd";
import InputGroup from "../../core/InputGroup";

type Props = {
    id?: number;
};
const CreateUpdateRole: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { loading, role } = useAppSelector((state) => state.role);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setRole({
                name: event.target.name,
                value: event.target.value,
            })
        );
    };

    const handleOpen = () => {
        setOpen(true);
        if (id) {
            dispatch(getOneRole(id));
        }
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(clearRole());
    };

    const handleSubmit = async () => {
        try {
            let res: any = await dispatch(
                id
                    ? updateRole(role as RoleCreateUpdateDTO)
                    : createRole(role as RoleCreateUpdateDTO)
            );
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    handleClose();
                    dispatch(getAllRoles({size: 25, page:0}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <button
                className={`btn ${
                    id
                        ? "btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
                        : "btn-sm btn-primary"
                }`}
                onClick={handleOpen}
            >
                {id ? <SVG icon={<EditIcon />} className="svg-icon-2" /> : t("create")}
            </button>
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        {t(`${id ? "updateRole" : "createRole"}`)}
                        {loading ? <Spin className="d-flex ms-2" size="small" /> : ""}
                    </div>
                }
                open={open}
                onCancel={handleClose}
                cancelText={t("cancel")}
                onOk={handleSubmit}
                okText={t("save")}
            >
                <div className="row row-cols-1">
                    <div className="col">
                        <InputGroup
                            label={t("code")}
                            type="text"
                            name="code"
                            required
                            value={role.code}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateUpdateRole;