import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, NavLink} from "react-router-dom";
import logo from "../../assets/logos/logo.svg";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {logoutUser} from "../../redux/services/authService";
import {ArrowIcon, ExitIcon, LogOutIcon} from "../Icons";
import {MenuData} from "./MenuData";
import {SVG} from "../core/SVG";
import ConfirmModal from "../core/ConfirmModal";
import {PermissionDTO} from "../../services/openapi";

const RenderMenu = ({
                        menu,
                        index,
                        t,
                        permissions
                    }: {
    menu: any;
    index: number;
    t: any;
    permissions: PermissionDTO[]
}) => {
    const [submenu, setSubmenu] = useState(-1);

    const toggleSubmenu = (e: any, state: string, idx: number) => {
        if (state) {
            e.preventDefault();
            setSubmenu((currentIndex) => (currentIndex !== idx ? idx : -1));
        }
        if (!state) {
            setSubmenu(-1);
        }
    };
    return (
        <div
            className={`menu-item menu-accordion ${
                submenu === index ? "hover show" : ""
            }`}
        >
            <NavLink
                className="menu-link"
                to={menu.link}
                onClick={(e) => toggleSubmenu(e, menu.tree ? "tree" : "", index)}
            >
        <span className="menu-icon">
          <span className="svg-icon svg-icon-2">{menu.icon}</span>
        </span>
                <span className="menu-title">{t(`${menu.name}`)}</span>
                {menu.tree && <span className="menu-arrow"></span>}
            </NavLink>
            <div
                className={`menu-sub menu-sub-accordion menu-active-bg ${
                    submenu === index && "show"
                }`}
            >
                {menu.tree?.map((tr: any, i: number) => {
                    return (
                        permissions.map(el => el.code === tr.permission && <div className="menu-item" key={i}>
                            <NavLink className="menu-link" to={tr.link}>
                <span className="menu-bullet">
                  <span className="bullet bullet-dot"></span>
                </span>
                                <span className="menu-title">{t(`${tr.name}`)}</span>
                            </NavLink>
                        </div>)
                    );
                })}
            </div>
        </div>
    );
};

type Props = {
    onDrawer?: string;
    isDrawer?: boolean;
    handleCloseAside?: any
}
const NavBar: React.FC<Props> = ({
                                     onDrawer,
                                     isDrawer,
                                     handleCloseAside
                                 }) => {
    const [active, setActive] = useState<any>(
        localStorage.getItem("aside") === "false"
    );
    const dispatch = useAppDispatch();

    const {permissions} = useAppSelector(state => state.user.userMe)

    const {t} = useTranslation();

    const asideToggle = () => {
        setActive(!active);
        localStorage.setItem("aside", active);
    };

    useEffect(() => {
        const aside = document.querySelector("main");
        if (active) {
            aside?.setAttribute("data-kt-aside-minimize", "on");
        } else {
            aside?.removeAttribute("data-kt-aside-minimize");
        }
    }, [active]);

    const logOut = () => {
        dispatch(logoutUser());
    };

    let logoRef = React.createRef<HTMLInputElement>();
    const footerRef = React.createRef<HTMLInputElement>();
    const mobileLogoRef = React.createRef<HTMLInputElement>();

    useEffect(() => {
        let menu: any = document.querySelector(".aside-menu");
        if (logoRef.current && footerRef.current && mobileLogoRef.current) {
            let height =
                logoRef.current?.clientHeight + footerRef.current?.clientHeight + mobileLogoRef.current?.clientHeight;
            if (menu) {
                menu.style.minHeight = `calc(100% - ${height}px)`;
                menu.style.maxHeight = `calc(100% - ${height}px)`;
                menu.style.overflowX = "hidden";
                menu.style.overflowY = "scroll";
            }
        }
    }, [logoRef, footerRef, mobileLogoRef]);

    return (
        <div className={`aside aside-dark aside-hoverable ${isDrawer && "drawer drawer-start"} ${onDrawer}`}>
            <div
                className="d-flex align-items-center justify-content-between d-lg-none ps-8 pt-4"
                ref={mobileLogoRef}
            >
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <Link to="/" className="d-lg-none">
                        <img alt="Logo" src={logo} className="h-30px"/>
                    </Link>
                </div>
                <div
                    className="btn btn-icon btn-active-light-primary"
                    id="kt_aside_mobile_toggle"
                    onClick={handleCloseAside}
                >
                        <span className="svg-icon svg-icon-2x mt-1">
                          <ExitIcon/>
                        </span>
                </div>
            </div>
            <div className="aside-logo flex-column-auto" ref={logoRef}>
                <Link to="/" className="logo">
                    <img src={logo} alt="logo" className="logo"/>
                </Link>
                <div
                    className={[
                        "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
                        active ? "active" : "",
                    ].join(" ")}
                    onClick={asideToggle}
                >
          <span className="svg-icon svg-icon-1 rotate-180">
            <ArrowIcon/>
          </span>
                </div>
            </div>
            <div className="aside-menu flex-column-fluid">
                <div
                    className="my-5 my-lg-5"
                    data-kt-scroll="true"
                >
                    <div
                        className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
                        {MenuData.map((menu, i) => {
                            return permissions && permissions!.map((el) => {
                                return el.code === menu.permission as keyof PermissionDTO && <RenderMenu permissions={permissions as PermissionDTO[]} key={i} menu={menu} t={t} index={i} />
                            })
                        })}
                    </div>
                </div>
            </div>
            <div
                className="aside-footer flex-column-auto pt-5 pb-7 px-5"
                id="kt_aside_footer"
                ref={footerRef}
            >
                <ConfirmModal
                    btnClass="btn-custom btn-primary w-100"
                    btnTitle={t("logout")}
                    title={t("confirmLogout")}
                    icon={<SVG icon={ <span className="svg-icon svg-icon-xl-1 ms-2"> <LogOutIcon/> </span> } /> }
                    handleSubmit={logOut}
                />
            </div>
        </div>
    );
};

export default NavBar;
