import React, {useState} from "react";
import {formatMoney} from "../../../helpers/formatMoney";
import {useTranslation} from "react-i18next";
import {SVG} from "../../core/SVG";
import {DownloadIcon, EyeIcon} from "../../Icons";
import {Modal} from "antd";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {getOneCheckedSuppliers} from "../../../redux/services/supplierService";
import {clearSupplier} from "../../../redux/slices/supplier";
import CourtMonitoring from "./CourtMonitoring";
import {
  CheckedSupplierControllerService,
  CheckedSupplierMibCreditorDocumentDTO, CheckedSupplierMibDebtorDocumentDTO, CheckedSupplierMinjustDTO,
  CheckedSupplierSudMonitoringDto,
} from "../../../services/openapi";
import {toast} from "react-toastify";
import instance from "../../../axios";
// import {muskNumber} from "../../../helpers/muskNumber";
import OrganizationInfo from "./OrganizationInfo";
import {getCurrencyType} from "../../../redux/services/dictionaryService";

type Props = {
  id: number;
};

const Supplier: React.FC<Props> = ({id}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [description, setDescrition] = useState<string>("");

  const {
    supplier,
    sudFiles,
    mibFiles,
    // directorMibFiles,
    minjustFiles,
    mintransFiles,
  } = useAppSelector((state) => state.supplier);
  const {userMe} = useAppSelector((state) => state.user);
  const {currency} = useAppSelector((state) => state.dictionary);

  const handleOpen = () => {
    setOpen(true);
    dispatch(getCurrencyType());
    dispatch(getOneCheckedSuppliers(id));
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearSupplier());
  };
  // const getColor = (taxGap: any) => {
  //   if (taxGap! >= 0.0 && taxGap! <= 0.2) {
  //     return `rgb(176,225,125)`;
  //   }
  //   if (taxGap! >= 0.21 && taxGap! <= 0.4) {
  //     return `rgb(254, 191, 77)`;
  //   }
  //   if (taxGap! >= 0.41 && taxGap! <= 0.6) {
  //     return `rgb(244, 124, 124)`;
  //   }
  //   if (taxGap! >= 0.61 && taxGap! <= 1) {
  //     return `rgb(173, 77, 77)`;
  //   }
  // };
  // const getBandText = (taxGap: any) => {
  //   if (taxGap! >= 0.0 && taxGap! <= 0.2) {
  //     return "Умеренная";
  //   }
  //   if (taxGap! >= 0.21 && taxGap! <= 0.4) {
  //     return "Средняя";
  //   }
  //   if (taxGap! >= 0.41 && taxGap! <= 0.6) {
  //     return "Высокая";
  //   }
  //   if (taxGap! >= 0.61 && taxGap! <= 1) {
  //     return "Критическая";
  //   }
  // };
  // const renderScore = (color: string) => {
  //   return Array(3)
  //     .fill("")
  //     .map((_, i) => (
  //       <div key={i} className={`w-20px h-40px border-end ${color}`}></div>
  //     ));
  // };
  const ratingText = (text: any) => {
    return text.toUpperCase();
  };

  const ratingValue = (text: any) => {
    let result = text;
    if (result.toLowerCase() === "a") {
      return "Высокий уровень - 3";
    }
    if (result.toLowerCase() === "aa") {
      return "Очень высокий уровень - 2";
    }
    if (result.toLowerCase() === "aaa") {
      return "Наивысший уровень - 1";
    }

    if (result.toLowerCase() === "b") {
      return "Умеренно высокий уровень - 3";
    }
    if (result.toLowerCase() === "bb") {
      return "Умеренно высокий уровень - 2";
    }
    if (result.toLowerCase() === "bbb") {
      return "Умеренно высокий уровень - 1";
    }

    if (result.toLowerCase() === "c") {
      return "Удовлетворительный уровень - 3";
    }
    if (result.toLowerCase() === "cc") {
      return "Удовлетворительный уровень - 2";
    }
    if (result.toLowerCase() === "ccc") {
      return "Удовлетворительный уровень - 1";
    }

    if (result.toLowerCase() === "d") {
      return "Низкий уровень - 3";
    }
    if (result.toLowerCase() === "dd") {
      return "Низкий уровень - 2";
    }
    if (result.toLowerCase() === "ddd") {
      return "Низкий уровень - 1";
    }
    return result;
  };

  function capitalizeFirstLetter(text: any) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  }

  const hasNumbers = (str: any) => {
    return /\d/.test(str);
  };
  const downloadFile = async (url?: string, name?: string) => {
    try {
      let res = await instance.get(`/api/v1/file${url}`, {
        responseType: "blob",
      });
      if (res.data) {
        let blob = new Blob([res.data], {type: "application/pdf"});
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err: any) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  const addDescription = async () => {
    try {
      let res = await CheckedSupplierControllerService.appDescUpdateUsingPost({
        appId: id,
        description: description,
      });
      toast(res.message, {type: "success"});
      await dispatch(getOneCheckedSuppliers(id));
      setDescrition("");
    } catch (err: any) {
      toast(err?.body?.errors[0]?.errorMsg || err.message, {
        type: "error",
      });
    }
  };

  return (
    <>
      <button
        className={
          "btn btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
        }
        onClick={handleOpen}
      >
        <SVG icon={<EyeIcon/>} className="svg-icon-2"/>
      </button>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {t("checkedSupplier")}
          </div>
        }
        open={open}
        onCancel={handleClose}
        cancelText={t("cancel")}
        onOk={handleClose}
        width={1400}
        className="supplier-info"
      >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{t("commonInfo")}</h3>
                  </div>
                </div>
                <div className="card-body p-9">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("dateGovRegister")}
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier.registerDate}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("numGovRegister")}
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.registerNumber}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">{t("tin")}</label>
                    <div className="col-lg-6 fv-row">
                      <span className="fw-bolder fs-6">{supplier?.inn}</span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">{t("orgName")}</label>
                    <div className="col-lg-6 fv-row">
                      <span className="fw-bolder fs-6">
                        {supplier?.organizationName}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">{t("codeOked")}</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier.oked}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Основной вид деятельности
                    </label>
                    <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {capitalizeFirstLetter(supplier?.activity)}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Юридический адрес
                    </label>
                    <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {capitalizeFirstLetter(supplier?.address)}
                      </span>
                    </div>
                  </div>

                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    {t("typeOfOwnership")}*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6 d-flex align-items-center">*/}
                  {/*    <span className="fw-bolder fs-6 me-2">*/}
                  {/*      {capitalizeFirstLetter(supplier?.typeOfOwnership)}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("numberOfWorkDay")}
                    </label>
                    <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {supplier?.numberOfWorkDay}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Руководитель юридического лица
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.directorName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Бухгалтер</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {capitalizeFirstLetter(supplier?.accountantName)}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Уставный капитал</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {formatMoney(supplier?.ustavCapital?.match(/\d+(\.\d+)?/g))} {' '}
                        {/*@ts-ignore*/}
                        { currency?.find(el =>
                          el?.code?.toLowerCase() === (supplier?.ustavCapital?.match(/[a-zA-Z]+/g)?.[0] || '')
                        )?.name }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h5 className="fw-bolder m-0">Учредители</h5>
                  </div>
                </div>
                <div className="card-body">
                  {supplier?.founders?.map((el, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-bold">Учредитель</label>
                          <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {el.name}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-bold">{t("residualBalance")}</label>
                          <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {/*{el?.inn && el?.inn?.length > 9 ? muskNumber(el.inn) : el.inn}*/}
                              {el.percent !== undefined && supplier.ustavCapital
                                ? formatMoney((Number(el.percent) * Number(supplier?.ustavCapital?.split(' ')[0])) / 100)
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-6 fw-bold">
                            Доля в уставном капитале %
                          </label>
                          <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {el.percent}
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Банковские реквизиты</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Расчетный счет</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.account}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">МФО банка</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.mfo}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Наименование банка
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.bank}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{t("supplierObject")}</h3>
                  </div>
                </div>
                <div className="card-body">
                  {supplier.organizationCadasters
                    ? supplier.organizationCadasters.map((el, i) => {
                      return el.number!.length ? (
                        <React.Fragment key={i}>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("cadastreNumber")}
                            </label>
                            <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.number}
                                </span>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("area")}
                            </label>
                            <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.area}
                                </span>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("ban")}
                            </label>
                            <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el.ban ? "Да" : "Нет"}
                                </span>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("info")}
                            </label>
                            <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.info}
                                </span>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="row mb-7" key={i}>
                          <label className="col-lg-6 fw-bold">Сведения</label>
                          <div className="col-lg-6">
                              <span className="fw-bolder fs-6 text-dark">
                                Отсутствуют
                              </span>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Наличие судебных дел контрагента
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  {userMe?.organization?.tariffType === "PLATINUM" ? (
                    <>
                      <div className="row mb-7">
                        <label className="col-lg-6 fw-bold">
                          Суды по Экономическим делам
                        </label>
                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isEconomicSud ? "Да" : "Нет"}
                          </span>
                        </div>
                      </div>
                      <div
                        className={[
                          "row d-flex flex-column",
                          sudFiles?.length > 0 ? " " : "mb-7",
                        ].join(" ")}
                      >
                        <label className="col-12 fw-bold">Решения суда</label>
                        <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                          {/*<span className="fw-bolder fs-6 text-dark">*/}
                          {/*<div className="badge badge-light-danger fs-6">*/}
                          {/*  В процессе*/}
                          {/*</div>*/}
                          {/*</span>*/}
                          {sudFiles?.map((file, i) => {
                            return (
                              <a
                                onClick={() =>
                                  downloadFile(file?.url, file?.name)
                                }
                                className="col py-3 ps-0 mb-3"
                                key={i}
                                href="#/"
                              >
                                <div
                                  className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                  <div className="text-truncate">
                                    {file?.name}
                                  </div>
                                  <button
                                    className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                    <SVG
                                      icon={<DownloadIcon/>}
                                      className="svg-icon-2"
                                    />
                                  </button>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                      <div className="row mb-7">
                        <label className="col-lg-6 fw-bold">
                          {t("courtMonitoring")}
                        </label>
                        <div className="col-lg-6">
                          {supplier.isEconomicSud ? (
                            <CourtMonitoring
                              data={
                                supplier?.sudMonitorings as CheckedSupplierSudMonitoringDto[]
                              }
                            />
                          ) : (
                            <span className="fw-bolder fs-6 text-dark">
                              Отсутствуют
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="badge badge-light-danger fs-6">
                      В ТАРИФЕ PLATINUM
                    </div>
                  )}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Суды по Уголовным делам*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <span className="fw-bolder fs-6 text-dark">*/}
                  {/*      {supplier?.isCriminalSud ? "Да" : "Нет"}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{t("lastCheckGNK")}</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Тип проверки</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.lastCheckGNK?.toString()?.split(',').join(', ')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Наличие исполнительных дел в Бюро принудительного исполнения
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Сумма задолженности контрагента
                    </h3>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Сведения
                    </label>
                    <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isOrganizationMibDebtor ? "Да" : "Нет"}
                          </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Долг</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.organizationMibDebt === null
                          ? ""
                          : supplier?.organizationMibDebt === "0"
                            ? t("no")
                            : formatMoney(supplier?.organizationMibDebt) + " сум"}
                        {/*{ || "Нет"}*/}

                        {/*{hasNumbers(supplier?.organizationMibDebt)*/}
                        {/*  ? " сум"*/}
                        {/*  : ""}*/}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("info")}
                    </label>
                    <div className="col-lg-6">
                      {supplier.isOrganizationMibDebtor ? (
                        <OrganizationInfo
                          title="БПИ / должники"
                          data={
                            supplier?.organizationMibDebtorDocuments as CheckedSupplierMibDebtorDocumentDTO[]
                          }
                          type="debtor"
                        />
                      ) : (
                        <span className="fw-bolder fs-6 text-dark">
                            Отсутствуют
                           </span>
                      )}
                    </div>
                    <div
                      className={[
                        "row",
                        mibFiles?.length > 0 ? " " : "mb-7",
                      ].join(" ")}
                    >
                      <label className="col-lg-6 fw-bold">{t("file")}</label>
                      {userMe?.organization?.tariffType !== "PLATINUM" ? (
                        <div className="col-lg-6">
                          <div className="badge badge-light-danger fs-6">
                            В ТАРИФЕ PLATINUM
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                          {mibFiles?.map((file, i) => {
                            return (
                              <a
                                onClick={() =>
                                  downloadFile(file?.url, file?.name)
                                }
                                className="col py-3 ps-0 mb-3"
                                key={i}
                                href="#/"
                              >
                                <div
                                  className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                  <div className="text-truncate">
                                    {file?.name}
                                  </div>
                                  <button
                                    className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                    <SVG
                                      icon={<DownloadIcon/>}
                                      className="svg-icon-2"
                                    />
                                  </button>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Сумма задолженности контрагенту
                    </h3>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Сведения
                    </label>
                    <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isOrganizationMibCreditor ? "Да" : "Нет"}
                          </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Долг
                    </label>
                    <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.organizationMibCreditorAmount === null
                              ? ""
                              : supplier?.organizationMibCreditorAmount === "0"
                                ? t("no")
                                : formatMoney(supplier?.organizationMibCreditorAmount) + " сум"}

                          </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("info")}
                    </label>
                    <div className="col-lg-6">
                      {supplier.isOrganizationMibCreditor ? (
                        <OrganizationInfo
                          title="БПИ / долг контрагента"
                          data={
                            supplier?.organizationMibCreditorDocuments as CheckedSupplierMibCreditorDocumentDTO[]
                          }
                          type="creditor"
                        />
                      ) : (
                        <span className="fw-bolder fs-6 text-dark">
                               Отсутствуют
                             </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Недобросовестные исполнители в едином реестре АО
                      "Узбекская республиканская товарно-сырьевая биржа"
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  {supplier?.unscrupulousPerformerNumber !== null ? (
                    <>
                      <div className="row mb-7">
                        <label className="col-lg-6 fw-bold">
                          Номер решения суда
                        </label>
                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            {supplier?.unscrupulousPerformerExitDate}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-7">
                        <label className="col-lg-6 fw-bold">
                          Дата внесения
                        </label>
                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            <span className="fw-bolder fs-6">
                              {supplier?.unscrupulousPerformerEntryDate}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="row mb-7">
                        <label className="col-lg-6 fw-bold">
                          Дата удаления
                        </label>
                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            <span className="fw-bolder fs-6">
                              {supplier?.unscrupulousPerformerExitDate}
                            </span>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">Сведения</label>
                      <div className="col-lg-6 fv-row">
                        <span className="fw-bolder fs-6">
                          <span className="fw-bolder fs-6">Отсутствуют</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Зарегистрированные лицензии в реестре Министерства Юстиции
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Сведения о лицензиях
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.licenseOfMinyust ? "Имеются" : "Отсутствуют"}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("info")}
                    </label>
                    <div className="col-lg-6">
                      {supplier.licenseOfMinyust ? (
                        <OrganizationInfo
                          title="Минюст"
                          data={
                            supplier?.organizationMinjusts as CheckedSupplierMinjustDTO[]
                          }
                          type="minJust"
                        />
                      ) : (
                        <span className="fw-bolder fs-6 text-dark">
                               Отсутствуют
                             </span>
                      )}
                    </div>
                  </div>
                  <div
                    className={[
                      "row",
                      minjustFiles?.length > 0 ? " " : "mb-7",
                    ].join(" ")}
                  >
                    <label className="col-lg-6 fw-bold">{t("file")}</label>
                    {userMe?.organization?.tariffType !== "PLATINUM" ? (
                      <div className="col-lg-6">
                        <div className="badge badge-light-danger fs-6">
                          В ТАРИФЕ PLATINUM
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                        {minjustFiles?.map((file, i) => {
                          return (
                            <a
                              onClick={() =>
                                downloadFile(file?.url, file?.name)
                              }
                              className="col py-3 ps-0 mb-3"
                              key={i}
                              href="#/"
                            >
                              <div
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                <div className="text-truncate">
                                  {file?.name}
                                </div>
                                <button
                                  className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                  <SVG
                                    icon={<DownloadIcon/>}
                                    className="svg-icon-2"
                                  />
                                </button>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Зарегистрированные лицензии в Министерстве Транспорта
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Сведения о лицензиях
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.licenseOfmintrans
                          ? "Имеются"
                          : "Отсутствуют"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={[
                      "row d-flex flex-column",
                      mintransFiles?.length > 0 ? " " : "mb-7",
                    ].join(" ")}
                  >
                    <label className="col-lg-6 fw-bold">{t("file")}</label>
                    {userMe?.organization?.tariffType !== "PLATINUM" ? (
                      <div className="col-lg-6">
                        <div className="badge badge-light-danger fs-6">
                          В ТАРИФЕ PLATINUM
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                        {mintransFiles?.map((file, i) => {
                          return (
                            <a
                              onClick={() =>
                                downloadFile(file?.url, file?.name)
                              }
                              className="col py-3 ps-0 mb-3"
                              key={i}
                              href="#/"
                            >
                              <div
                                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                <div className="text-truncate">
                                  {file?.name}
                                </div>
                                <button
                                  className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                  <SVG
                                    icon={<DownloadIcon/>}
                                    className="svg-icon-2"
                                  />
                                </button>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Налоговая информация</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Налоговая задолженность контрагента
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {formatMoney(supplier?.taxDebt) || "Нет"}{" "}
                        {hasNumbers(supplier?.taxDebt) ? " сум" : ""}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Статус налогоплательщика
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="fw-bolder fs-6">
                        {capitalizeFirstLetter(supplier?.activityStatus)}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Количество сотрудников
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.numberOfEmployee}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Крупный налогоплательщик
                    </label>
                    <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {supplier?.largeTaxPayer ? t("yes") : t("no")}
                      </span>
                    </div>
                  </div>

                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">Налоговый режим</label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {supplier?.isNdsPayer && (
                    <div className="row">
                      <div className="col-12">
                        <div className="card-header cursor-pointer ps-0">
                          <div className="card-title m-0">
                            <h5 className="fw-bolder m-0">
                              Свидетельство о государственной регистрации в
                              качестве плательщика НДС:
                            </h5>
                          </div>
                        </div>
                        <div className="card-body px-0">
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("dateGovRegister")}
                            </label>
                            <div className="col-lg-6">
                              <div className="fw-bolder fs-6 text-dark">
                                {supplier?.ndsRegDate}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              Номер налогоплательщика НДС
                            </label>
                            <div className="col-lg-6">
                              <div className="fw-bolder fs-6 text-dark">
                                {supplier.ndsRegNumber}
                              </div>
                            </div>
                          </div>
                          <div className="row mb-7">
                            <label className="col-lg-6 fw-bold">
                              {t("status")}
                            </label>
                            <div className="col-lg-6">
                              <div className="fw-bolder fs-6 text-dark">
                                {supplier.ndsStatus}
                              </div>
                            </div>
                          </div>
                          {
                            // <div className="row mb-7">
                            //   <label className="col-lg-6 d-flex align-items-center fw-bold">
                            //     Коэффициент разрыва при уплате НДС (по всей
                            //     цепочке)
                            //   </label>
                            //   <div className="col-lg-6 pe-12">
                            //     <div className="d-flex justify-content-start align-items-center fw-bolder fs-6 text-dark w-100 mt-auto mb-2">
                            //       <span
                            //         style={{
                            //           color: getColor(supplier?.taxGap),
                            //         }}
                            //       >
                            //         {supplier?.taxGap}
                            //       </span>
                            //       <div className="h-40px w-100px ms-10 bg-white rounded">
                            //         <div
                            //           style={{
                            //             background: getColor(supplier?.taxGap),
                            //           }}
                            //           className="rounded h-40px w-100"
                            //           role="progressbar"
                            //         ></div>
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                          }
                          <div className="row mb-2">
                            <label className="col-lg-6 fw-bold">
                              Находится в списке предприятий, злоупотребивших
                              правом уменьшения суммы НДС, подлежащей уплате
                            </label>
                            <div className="col-lg-6 d-flex align-items-center">
                              <div className="fw-bolder fs-6 text-dark">
                                {supplier?.obnalStatus ? "Да" : "Нет"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*<hr />*/}
                    </div>
                  )}
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      {t("budgetOrganization")}
                    </label>
                    <div className="col-lg-6">
                      <div className="fw-bolder fs-6 text-dark">
                        {supplier?.isBudget ? t("yes") : t("no")}
                      </div>
                    </div>
                  </div>
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Дата окончания деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Причина окончания деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Дата временной приостановки деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Причина временной приостановки деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark"></div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">Вид деятельности</label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark">*/}
                  {/*      {capitalizeFirstLetter(supplier?.activity)}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    {t('dateGovRegister')}*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark">*/}
                  {/*      {supplier.registerDate}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Срок деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark">*/}
                  {/*      {supplier?.dateEnd}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Состояние деятельности*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark">*/}
                  {/*      {capitalizeFirstLetter(supplier?.activityStatus)}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="row mb-7">*/}
                  {/*  <label className="col-lg-6 fw-bold">*/}
                  {/*    Номер регистрации в реестре*/}
                  {/*  </label>*/}
                  {/*  <div className="col-lg-6">*/}
                  {/*    <div className="fw-bolder fs-6 text-dark">*/}
                  {/*      {supplier?.registerNumber}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Электронный рейтинг строительно-подрядных организаций
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card-header cursor-pointer ps-0">
                        <div className="card-title m-0">
                          <h5 className="fw-bolder m-0">
                            Общестроительно-социальные объекты
                          </h5>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-lg-6 fw-bold">
                          Рейтинг доверия / Балл
                        </label>
                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingGeneralConstruction
                              ? ratingText(supplier.ratingGeneralConstruction) +
                              " (" +
                              ratingValue(
                                supplier.ratingGeneralConstruction
                              ) +
                              ")"
                              : "Не имеется"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card-header cursor-pointer ps-0">
                        <div className="card-title m-0">
                          <h5 className="fw-bolder m-0">
                            Мелиорация и Орошение
                          </h5>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-lg-6 fw-bold">
                          Рейтинг доверия / Балл
                        </label>
                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingReclamationAndIrrigation
                              ? ratingText(
                                supplier.ratingReclamationAndIrrigation
                              ) +
                              " / " +
                              ratingValue(
                                supplier.ratingReclamationAndIrrigation
                              )
                              : "Не имеется"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card-header cursor-pointer ps-0">
                        <div className="card-title m-0">
                          <h5 className="fw-bolder m-0">
                            Автомобильные дороги, мосты
                          </h5>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-lg-6 fw-bold">
                          Рейтинг доверия / Балл
                        </label>
                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingHighwaysAndBridges
                              ? ratingText(supplier.ratingHighwaysAndBridges) +
                              " / " +
                              ratingValue(supplier.ratingHighwaysAndBridges)
                              : "Не имеется"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card-header cursor-pointer ps-0">
                        <div className="card-title m-0">
                          <h5 className="fw-bolder m-0">
                            Проектные организации
                          </h5>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-lg-6 fw-bold">
                          Рейтинг доверия / Балл
                        </label>
                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingDesignOrganizations
                              ? ratingText(supplier.ratingDesignOrganizations) +
                              " / " +
                              ratingValue(supplier.ratingDesignOrganizations)
                              : "Не имеется"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="card mb-5 mb-xl-8">*/}
              {/*  <div className="card-header cursor-pointer">*/}
              {/*    <div className="card-title m-0">*/}
              {/*      <h3 className="fw-bolder m-0">Информация о руководителе</h3>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="card-body">*/}
              {/*    <div className="row mb-7">*/}
              {/*      <label className="col-lg-6 fw-bold">{t("fio")}</label>*/}
              {/*      <div className="col-lg-6">*/}
              {/*        <span className="fw-bolder fs-6 text-dark">*/}
              {/*          {supplier?.directorName}*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="row mb-7">*/}
              {/*      <label className="col-lg-6 fw-bold">{t("tin")}</label>*/}
              {/*      <div className="col-lg-6">*/}
              {/*        <span className="fw-bolder fs-6 text-dark">*/}
              {/*          {muskNumber(supplier?.directorTin)}*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="row mb-7">*/}
              {/*      <label className="col-lg-6 fw-bold">ПИНФЛ</label>*/}
              {/*      <div className="col-lg-6">*/}
              {/*        <span className="fw-bolder fs-6 text-dark">*/}
              {/*          {muskNumber(supplier?.directorPinfl)}*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!*<div className="row mb-7">*!/*/}
              {/*    /!*  <label className="col-lg-6 fw-bold">*!/*/}
              {/*    /!*    Наличие исполнительного дела в БПИ*!/*/}
              {/*    /!*  </label>*!/*/}
              {/*    /!*  <div className="col-lg-6">*!/*/}
              {/*    /!*    <span className="fw-bolder fs-6 text-dark">*!/*/}
              {/*    /!*      {supplier?.directorMibDebt === null*!/*/}
              {/*    /!*        ? ""*!/*/}
              {/*    /!*        : supplier?.directorMibDebt === "0"*!/*/}
              {/*    /!*          ? t("no")*!/*/}
              {/*    /!*          : formatMoney(supplier?.directorMibDebt) + " сум"}*!/*/}
              {/*    /!*      /!*{capitalizeFirstLetter(formatMoney(supplier?.directorMibDebt)) ||*!/*!/*/}
              {/*    /!*      /!*  "Нет"}*!/*!/*/}
              {/*    /!*      /!*{hasNumbers(supplier?.directorMibDebt) ? " сум" : ""}*!/*!/*/}
              {/*    /!*    </span>*!/*/}
              {/*    /!*  </div>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*    <div*/}
              {/*      className={[*/}
              {/*        "row d-flex flex-column",*/}
              {/*        directorMibFiles?.length > 0 ? " " : "mb-7",*/}
              {/*      ].join(" ")}*/}
              {/*    >*/}
              {/*      <label className="col-12 fw-bold">Файлы</label>*/}
              {/*      <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">*/}
              {/*        {directorMibFiles?.map((file, i) => {*/}
              {/*          return (*/}
              {/*            <a*/}
              {/*              onClick={() => downloadFile(file?.url, file?.name)}*/}
              {/*              className="col py-3 ps-0 mb-3"*/}
              {/*              key={i}*/}
              {/*              href="#/"*/}
              {/*            >*/}
              {/*              <div*/}
              {/*                className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">*/}
              {/*                <div className="text-truncate">{file?.name}</div>*/}
              {/*                <button className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">*/}
              {/*                  <SVG*/}
              {/*                    icon={<DownloadIcon/>}*/}
              {/*                    className="svg-icon-2"*/}
              {/*                  />*/}
              {/*                </button>*/}
              {/*              </div>*/}
              {/*            </a>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!*<div className="row mb-7">*!/*/}
              {/*    /!*  <label className="col-lg-6 fw-bold">*!/*/}
              {/*    /!*    Находится в списке розыскиваемых лиц в БПИ*!/*/}
              {/*    /!*  </label>*!/*/}
              {/*    /!*  <div className="col-lg-6">*!/*/}
              {/*    /!*    <span className="fw-bolder fs-6 text-dark"></span>*!/*/}
              {/*    /!*  </div>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*    /!*<div className="row mb-7">*!/*/}
              {/*    /!*  <label className="col-lg-6 fw-bold">*!/*/}
              {/*    /!*    Ограничение на выезд*!/*/}
              {/*    /!*  </label>*!/*/}
              {/*    /!*  <div className="col-lg-6">*!/*/}
              {/*    /!*    <span className="fw-bolder fs-6 text-dark"></span>*!/*/}
              {/*    /!*  </div>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*    /!*<div className="row mb-7">*!/*/}
              {/*    /!*  <label className="col-lg-6 fw-bold">Судебные дела</label>*!/*/}
              {/*    /!*  <div className="col-lg-6">*!/*/}
              {/*    /!*    <span className="fw-bolder fs-6 text-dark">*!/*/}
              {/*    /!*      {supplier?.sud ? "Имеются" : "Не имеются"}*!/*/}
              {/*    /!*    </span>*!/*/}
              {/*    /!*  </div>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="card mb-5 mb-xl-8">*/}
              {/*  <div className="card-header cursor-pointer">*/}
              {/*    <div className="card-title m-0">*/}
              {/*      <h3 className="fw-bolder m-0">{t("directorObject")}</h3>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="card-body">*/}
              {/*    {supplier.directorCadasters*/}
              {/*      ? supplier.directorCadasters.map((el, i) => {*/}
              {/*        return el.number!.length ? (*/}
              {/*          <React.Fragment key={i}>*/}
              {/*            <div className="row mb-7">*/}
              {/*              <label className="col-lg-6 fw-bold">*/}
              {/*                {t("cadastreNumber")}*/}
              {/*              </label>*/}
              {/*              <div className="col-lg-6">*/}
              {/*                  <span className="fw-bolder fs-6 text-dark">*/}
              {/*                    {el?.number}*/}
              {/*                  </span>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="row mb-7">*/}
              {/*              <label className="col-lg-6 fw-bold">*/}
              {/*                {t("area")}*/}
              {/*              </label>*/}
              {/*              <div className="col-lg-6">*/}
              {/*                  <span className="fw-bolder fs-6 text-dark">*/}
              {/*                    {el?.area}*/}
              {/*                  </span>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="row mb-7">*/}
              {/*              <label className="col-lg-6 fw-bold">*/}
              {/*                {t("ban")}*/}
              {/*              </label>*/}
              {/*              <div className="col-lg-6">*/}
              {/*                  <span className="fw-bolder fs-6 text-dark">*/}
              {/*                    {el.ban ? "Да" : "Нет"}*/}
              {/*                  </span>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="row mb-7">*/}
              {/*              <label className="col-lg-6 fw-bold">*/}
              {/*                {t("info")}*/}
              {/*              </label>*/}
              {/*              <div className="col-lg-6">*/}
              {/*                  <span className="fw-bolder fs-6 text-dark">*/}
              {/*                    {el?.info}*/}
              {/*                  </span>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </React.Fragment>*/}
              {/*        ) : (*/}
              {/*          <div className="row mb-7" key={i}>*/}
              {/*            <label className="col-lg-6 fw-bold">Сведения</label>*/}
              {/*            <div className="col-lg-6">*/}
              {/*                <span className="fw-bolder fs-6 text-dark">*/}
              {/*                  Отсутствуют*/}
              {/*                </span>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        );*/}
              {/*      })*/}
              {/*      : ""}*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Взаимосвязанные лица
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  {userMe?.organization?.tariffType === "PLATINUM" ? (
                    supplier?.affiliations ? (
                      supplier?.affiliations.map((el, i) => {
                        return el.tin!.length && el.organizationName!.length ? (
                          <React.Fragment key={i}>
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-bold">
                                {t("tin")}
                              </label>
                              <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.tin}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-7">
                              <label className="col-lg-6 fw-bold">
                                {t("orgName")}
                              </label>
                              <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.organizationName}
                                </span>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="row mb-7" key={i}>
                            <label className="col-lg-6 fw-bold">Сведения</label>
                            <div className="col-lg-6">
                              <span className="fw-bolder fs-6 text-dark">
                                Отсутствуют
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="badge badge-light-danger fs-6">
                      В ТАРИФЕ PLATINUM
                    </div>
                  )}
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">
                      Рейтинг устойчивости субъектов предпринимательства
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">
                      Категория рейтинга устойчивости
                    </label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark text-uppercase">
                        {supplier?.ratingCategory}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-6 fw-bold">Балл</label>
                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.ratingBall}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Степень риска контрагента</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <div className="col-lg-6">
                      <div className="scoring h-40px position-relative">
                        <div className="w-100 h-100 d-flex position-relative rounded-2 shadow-lg overflow-hidden">
                          <div
                            className="w-100 h-100 z-index-2
                                    position-absolute top-0 start-0 border-2 border-white
                                    border-solid
                                    bg-transparent rounded-2"
                            style={{
                              background:
                                "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
                            }}
                          ></div>
                        </div>
                        <div
                          className="scoring-line z-index-3
                                   position-absolute
                                     w-4px shadow-sm rounded"
                          style={{
                            left: `calc(${
                              50 + 2.5 * Number(supplier?.calculation)
                            }% - 2px)`,
                            backgroundColor: "#7a8117",
                          }}
                        >
                          <span
                            className="position-absolute
                            bg-white shadow-sm
                            d-flex
                            align-items-center
                            justify-content-center
                            bottom-100
                             text-dark rounded-circle h-35px w-35px"
                            style={{
                              left: "-16px",
                            }}
                          >
                            {supplier?.calculation !== null
                              ? supplier?.calculation?.includes("-")
                                ? supplier?.calculation
                                : "+" + supplier?.calculation
                              : "-/+"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Долг по налогам
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {formatMoney(supplier?.taxDebt) || "Нет"}{" "}
                          {hasNumbers(supplier?.taxDebt) ? "сум" : ""}
                        </span>
                      </div>
                    </div>
                    {/*<div className="row mb-7">*/}
                    {/*  <label className="col-lg-6 fw-bold">*/}
                    {/*    Долг в БПИ (физ.лица)*/}
                    {/*  </label>*/}
                    {/*  <div className="col-lg-6">*/}
                    {/*    <span className="fw-bolder fs-6 text-dark">*/}
                    {/*      {supplier?.directorMibDebt === null*/}
                    {/*        ? ""*/}
                    {/*        : supplier?.directorMibDebt === "0"*/}
                    {/*          ? t("no")*/}
                    {/*          : formatMoney(supplier?.directorMibDebt) + " сум"}*/}
                    {/*      /!*{formatMoney(supplier?.directorMibDebt) || "Нет"}*!/*/}
                    {/*      /!*{hasNumbers(supplier?.directorMibDebt) ? " cум" : ""}*!/*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/*<div className="row mb-7">*/}
                    {/*  <label className="col-lg-6 fw-bold">Показатель КНР</label>*/}
                    {/*  <div className="col-lg-6">*/}
                    {/*    <span className="fw-bolder fs-6 text-dark">*/}
                    {/*      {supplier?.taxGap}*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Наличие судебных дел
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.isEconomicSud ? "Да" : "Нет"}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Недобросовестный исполнитель
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.unscrupulousPerformerNumber ? "Дв" : "Нет"}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Долг в БПИ (юр.лица)
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.organizationMibDebt === null
                            ? ""
                            : supplier?.organizationMibDebt === "0"
                              ? t("no")
                              : formatMoney(supplier?.organizationMibDebt) +
                              " сум"}
                          {/*{formatMoney(supplier?.organizationMibDebt) || "Нет"}*/}
                          {/*{hasNumbers(supplier?.organizationMibDebt)*/}
                          {/*  ? " сум"*/}
                          {/*  : ""}*/}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        В списке сомнительных НК
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.obnalStatus ? "Да" : "Нет"}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Находится в процессе ликвидации
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.liquidation ? "Да" : "Нет"}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Зарегистрирован менее 3 месяцев
                      </label>
                      <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.isOrganization3MonthWork ? "Да" : "Нет"}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-7">
                      <label className="col-lg-6 fw-bold">
                        Статус налогоплательщика
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="fw-bolder fs-6">
                          {capitalizeFirstLetter(supplier?.activityStatus)}
                        </span>
                      </div>
                    </div>
                    {/*<div className="row mb-7">*/}
                    {/*  <label className="col-lg-6 fw-bold">*/}
                    {/*    Надежность*/}
                    {/*  </label>*/}
                    {/*  <div className="col-lg-6">*/}
                    {/*    <span className="btn min-w-100px fw-bolder fs-6 text-white"*/}
                    {/*          style={{*/}
                    {/*            background: getColor(supplier?.taxGap),*/}
                    {/*          }}*/}
                    {/*    >*/}
                    {/*      {relBandText(supplier?.taxGap)}*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>

              <div className="card mb-5 mb-xl-8">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Вниманию клиентов</h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-7">
                    <label className="col-lg-12">{supplier?.description}</label>
                  </div>
                </div>
              </div>

              {userMe?.role === "ADMIN" || userMe?.role === "SYS_ADMIN" ? (
                <div className="card mb-5 mb-xl-8">
                  <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                      <h3 className="fw-bolder m-0">Важная информация</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-7">
                      <div className="col-lg-12">
                        <div className="fv-row mt-0">
                          <label className="fs-6 fw-semibold form-label">
                            <span>{t("info")}</span>
                          </label>
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescrition(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 d-flex mt-2 justify-content-end">
                        <button
                          className="btn btn-primary"
                          onClick={addDescription}
                        >
                          {" "}
                          {t("save")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Supplier;
