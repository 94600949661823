
import {createSlice} from "@reduxjs/toolkit";
import {ResidentCompany, ResidentDTO} from "../../services/openapi";
import {getAllResidents} from "../services/residents";

interface ResidentState {
    residents: ResidentCompany[];
    loading: boolean;
    status: string;
    error: string;
    totalCount: any
}

const initialState: ResidentState = {
    residents: [],
    loading: false,
    status: "",
    error: "",
    totalCount: 0
};

export const residentSlice = createSlice({
    name: "resident",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllResidents.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllResidents.fulfilled.type]: (state, { payload }: {payload: ResidentDTO} ) => {
            state.status = "success";
            state.residents = payload.data?.companies || [];
            state.totalCount = payload.meta?.total;
            state.loading = false;
        },
        [getAllResidents.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});


export default residentSlice.reducer;
