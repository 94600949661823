import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { TokenDTO } from "../../services/openapi";
import { getCookie, removeCookie } from "./../../helpers/cookie";
import {removeStorage} from "../../helpers/localStorage";

let token = getCookie("_token");

interface IAuth extends TokenDTO {
  isLoading: boolean;
}

const initialState: IAuth = {
  accessToken: token ? token : undefined,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    logout: (state) => {
      removeCookie("_token");
      removeCookie("_refreshToken");
      removeStorage('user')
      state.accessToken = undefined;
    },
  },
});

export const { setAccessToken, setIsLoading, logout } = authSlice.actions;

export default authSlice.reducer;
