import { Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: JSX.Element;
  icon?: JSX.Element;
  btnTitle?: string;
  title: string;
  btnSize?: string;
  hiddenOK?: boolean;
  btnClass?: string;
  width?: number;
  handleSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  success?: boolean;
};

const CustomModal: React.FC<Props> = ({
                                        children,
                                        icon,
                                        btnTitle,
                                        title,
                                        btnSize,
                                        hiddenOK,
                                        btnClass,
                                        width,
                                        handleSubmit,
                                        success
                                      }) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
      <>
        <button
            className={["btn", btnClass || btnSize || ""].join(" ")}
            onClick={handleOpen}
        >
          {btnTitle}
          {icon}
        </button>
        <Modal
            open={show}
            title={title}
            onCancel={handleClose}
            cancelText={t("cancel")}
            onOk={handleSubmit}
            okText={t("save")}
            okButtonProps={{ style: { display: hiddenOK ? "none" : "inline" } }}
            width={width}
        >
          {children}
        </Modal>
      </>
  );
};

export default CustomModal;
