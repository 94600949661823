import React from "react";

type Props = {
    type: string;
    name: string;
    className?: string;
    placeholder?: string;
    value?: any;
    ref?: React.RefObject<HTMLInputElement>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};

const Input: React.FC<Props> = ({ name, type, className,placeholder,value,onChange,ref ,disabled}) => {
    return (
        <input
            className={`form-control form-control-sm h-35px ${className}`}
            type={type}
            name={name}
            ref={ref}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export default Input;
