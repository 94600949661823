/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfMessageDTO } from '../models/ApiResultOfMessageDTO';
import type { ApiResultOfPageOfMessageDTO } from '../models/ApiResultOfPageOfMessageDTO';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { MessageCreateUpdateDTO } from '../models/MessageCreateUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessageControllerService {

    /**
     * create
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static createUsingPost(
        dto: MessageCreateUpdateDTO,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/message',
            body: dto,
        });
    }

    /**
     * update
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static updateUsingPut(
        dto: MessageCreateUpdateDTO,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/message',
            body: dto,
        });
    }

    /**
     * getAll
     * @param page page
     * @param size size
     * @param id
     * @param key
     * @param textEn
     * @param textRu
     * @param textUz
     * @returns ApiResultOfPageOfMessageDTO OK
     * @throws ApiError
     */
    public static getAllUsingGet1(
        page: number,
        size: number,
        id?: number,
        key?: string,
        textEn?: string,
        textRu?: string,
        textUz?: string,
    ): CancelablePromise<ApiResultOfPageOfMessageDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/message/get-all',
            query: {
                'id': id,
                'key': key,
                'page': page,
                'size': size,
                'textEn': textEn,
                'textRu': textRu,
                'textUz': textUz,
            },
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfMessageDTO OK
     * @throws ApiError
     */
    public static getUsingGet1(
        id: number,
    ): CancelablePromise<ApiResultOfMessageDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/message/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static deleteUsingDelete(
        id: number,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/message/{id}',
            path: {
                'id': id,
            },
        });
    }

}
