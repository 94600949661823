import React from "react";

type Props = {
  label: string;
  type: string;
  name?: string;
  className?: string;
  groupClassName?: string;
  icon?: JSX.Element;
  required?: boolean;
  placeholder?: string;
  value?: any;
  ref?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
  maxLength?: number;
};

const InputGroup: React.FC<Props> = ({
  label,
  name,
  type,
  icon,
  required,
  placeholder,
  value,
  onChange,
  ref,
  disabled,
  className,
  groupClassName,
  pattern,
  inputMode,
  maxLength,
}) => {
  return (
    <div
      className={`fv-row mb-1 ${icon ? "fv-plugins-icon-container" : ""} ${
        groupClassName || ""
      }`}
    >
      <label className="fs-6 fw-semibold form-label mt-3">
        <span className={required ? "required" : ""}>{label}</span>
        {icon}
      </label>
      <input
        type={type}
        name={name}
        pattern={pattern}
        inputMode={inputMode}
        maxLength={maxLength}
        ref={ref}
        className={`form-control form-control-solid h-40px ${
          className ? className : ""
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={(event) => {
          if (event.which === 38 || event.which === 40) {
            event.preventDefault();
          }
        }}
        autoComplete="new-password"
      />
      <div className="fv-plugins-message-container invalid-feedback"></div>
    </div>
  );
};

export default InputGroup;
