import {
  ApiResultOfCheckedSupplierDto,
  ApplicationUpdateDTO,
  CaptchaBase64Img,
  CheckedSupplierDto,
  ObnalSupplierDto,
  UnscrupulousPerformerDTO,
} from "../../services/openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  completeApplication,
  createApp,
  createApplication,
  getAllCheckedSuppliers,
  getCaptcha,
  getDubiousOrganizations,
  getOneCheckedSuppliers,
  getSupplierInfo,
  getUnscrupulousPerformers,
  updateApplication,
} from "../services/supplierService";

interface SupplierState {
  suppliers: CheckedSupplierDto[];
  supplierInfo: CheckedSupplierDto;
  supplier: CheckedSupplierDto;
  application: ApplicationUpdateDTO;
  dubiousOrganizations: ObnalSupplierDto[];
  unscrupulousPerformers: UnscrupulousPerformerDTO[];
  captcha: CaptchaBase64Img;
  complete: ApiResultOfCheckedSupplierDto;
  create: ApiResultOfCheckedSupplierDto;
  update: ApiResultOfCheckedSupplierDto;
  sudFiles: any[];
  mibFiles: any[];
  directorMibFiles: any[];
  minjustFiles: any[];
  mintransFiles: any[];
  loading: boolean;
  dbLoader: boolean;
  sendLoader: boolean;
  captchaLoad: boolean;
  status: string;
  error: string;
  response: any;
  totalCount: any;
  dubiousTotalCount: any;
  unscrupulousTotal: any;
}

const initialState: SupplierState = {
  suppliers: [],
  supplierInfo: {},
  supplier: {},
  application: {},
  dubiousOrganizations: [],
  unscrupulousPerformers: [],
  captcha: {},
  create: {},
  complete: {},
  update: {},
  sudFiles: [],
  minjustFiles: [],
  mibFiles: [],
  directorMibFiles: [],
  mintransFiles: [],
  loading: false,
  dbLoader: false,
  sendLoader: false,
  captchaLoad: false,
  status: "",
  error: "",
  response: {},
  totalCount: 0,
  dubiousTotalCount: 0,
  unscrupulousTotal: 0,
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    setSupplier: (
      state,
      action: PayloadAction<{ name: any; value: string | number | boolean }>
    ) => {
      state.application = {
        ...state.application,
        [action.payload.name]: action.payload.value,
      };
    },
    setFounder: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.founders && [
          ...state.application.founders,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.founders = newItems;
      }
    },
    addFounder: (state) => {
      state.application.founders = [
        ...(state.application.founders ?? []),
        {
          name: "",
          percent: "",
          inn: "",
        },
      ];
    },
    removeFounder: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.founders ?? [])];
      list.splice(action.payload.index, 1);
      state.application.founders = list;
    },
    setDirObject: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.directorCadasters && [
          ...state.application.directorCadasters,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.directorCadasters = newItems;
      }
    },
    addDirObject: (state) => {
      state.application.directorCadasters = [
        ...(state.application.directorCadasters ?? []),
        {
          number: "",
          ban: undefined,
          area: "",
          info: "",
        },
      ];
    },
    removeDirObject: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.directorCadasters ?? [])];
      list.splice(action.payload.index, 1);
      state.application.directorCadasters = list;
    },
    setOrgObject: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.organizationCadasters && [
          ...state.application.organizationCadasters,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.organizationCadasters = newItems;
      }
    },
    addOrgObject: (state) => {
      state.application.organizationCadasters = [
        ...(state.application.organizationCadasters ?? []),
        {
          number: "",
          ban: undefined,
          area: "",
          info: "",
        },
      ];
    },
    removeOrgObject: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.organizationCadasters ?? [])];
      list.splice(action.payload.index, 1);
      state.application.organizationCadasters = list;
    },
    setAffiliation: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.affiliations && [
          ...state.application.affiliations,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.affiliations = newItems;
      }
    },
    addAffiliation: (state) => {
      state.application.affiliations = [
        ...(state.application.affiliations ?? []),
        {
          tin: "",
          organizationName: "",
        },
      ];
    },
    removeAffiliation: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.affiliations ?? [])];
      list.splice(action.payload.index, 1);
      state.application.affiliations = list;
    },
    setCourtMonitoring: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.sudMonitorings && [
          ...state.application.sudMonitorings,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.sudMonitorings = newItems;
      }
    },
    addCourtMonitoring: (state) => {
      state.application.sudMonitorings = [
        ...(state.application.sudMonitorings ?? []),
        {
          hearing_date: "",
          reg_date: "",
          claimant: "",
          defendant: "",
          casenumber: "",
          result: "",
        },
      ];
    },
    removeCourtMonitoring: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.sudMonitorings ?? [])];
      list.splice(action.payload.index, 1);
      state.application.sudMonitorings = list;
    },
    setOrganizationMibCreditorDocuments: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.organizationMibCreditorDocuments && [
          ...state.application.organizationMibCreditorDocuments,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.organizationMibCreditorDocuments = newItems;
      }
    },
    addOrganizationMibCreditorDocuments: (state) => {
      state.application.organizationMibCreditorDocuments = [
        ...(state.application.organizationMibCreditorDocuments ?? []),
        {
          branchName: "",
          branchPhone: "",
          executionDocContent: "",
          workNumber: "",
          residualBalance: "",
          debtorName: ""
        },
      ];
    },
    removeOrganizationMibCreditorDocuments: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.organizationMibCreditorDocuments ?? [])];
      list.splice(action.payload.index, 1);
      state.application.organizationMibCreditorDocuments = list;
    },
    setOrganizationMibDebtorDocuments: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.organizationMibDebtorDocuments && [
          ...state.application.organizationMibDebtorDocuments,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.organizationMibDebtorDocuments = newItems;
      }
    },
    addOrganizationMibDebtorDocuments: (state) => {
      state.application.organizationMibDebtorDocuments = [
        ...(state.application.organizationMibDebtorDocuments ?? []),
        {
          creditorName: "",
          branchName: "",
          branchPhone: "",
          executionDocContent: "",
          workNumber: "",
          residualBalance: ""
        },
      ];
    },
    removeOrganizationMibDebtorDocuments: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.organizationMibDebtorDocuments ?? [])];
      list.splice(action.payload.index, 1);
      state.application.organizationMibDebtorDocuments = list;
    },
    setOrganizationMinjusts: (
      state,
      action: PayloadAction<{
        index?: number;
        name: string | undefined;
        value: string | number;
      }>
    ) => {
      let { name, value, index } = action.payload;
      if (index !== undefined) {
        let newItems = state.application.organizationMinjusts && [
          ...state.application.organizationMinjusts,
        ];
        // @ts-ignore
        newItems[index][name] = value;
        state.application.organizationMinjusts = newItems;
      }
    },
    addOrganizationMinjusts: (state) => {
      state.application.organizationMinjusts = [
        ...(state.application.organizationMinjusts ?? []),
        {
          activityTypes: "",
          organizationName: "",
          registerNumber: "",
          documentType: "",
          expiryDate: '',
          registrationDate: ""
        },
      ];
    },
    removeOrganizationMinjusts: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const list = [...(state.application.organizationMinjusts ?? [])];
      list.splice(action.payload.index, 1);
      state.application.organizationMinjusts = list;
    },
    setUploadSudFile: (state, action) => {
      let items = [...(state.application.sudFiles ?? [])];
      items.push(action.payload);
      state.application.sudFiles = items;
    },
    setSudFiles: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.sudFiles.push(action.payload.data);
    },
    removeSudFile: (
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      let newItems = state.application.sudFiles?.filter(
        (el) => el !== action.payload.id
      );
      state.sudFiles = state.sudFiles?.filter(
        (el) => el?.id !== action.payload.id
      );
      state.application.sudFiles = newItems;
    },
    setUploadMibFile: (state, action) => {
      let items = [...(state.application.mibFiles ?? [])];
      items.push(action.payload);
      state.application.mibFiles = items;
    },
    setMibFiles: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.mibFiles.push(action.payload.data);
    },
    removeNibFile: (
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      let newItems = state.application.mibFiles?.filter(
        (el) => el !== action.payload.id
      );
      state.mibFiles = state.mibFiles?.filter(
        (el) => el?.id !== action.payload.id
      );
      state.application.mibFiles = newItems;
    },
    setUploadDirectorMibFile: (state, action) => {
      let items = [...(state.application.directorMibFiles ?? [])];
      items.push(action.payload);
      state.application.directorMibFiles = items;
    },
    setDirectorMibFiles: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.directorMibFiles.push(action.payload.data);
    },
    removeDirectorNibFile: (
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      let newItems = state.application.directorMibFiles?.filter(
        (el) => el !== action.payload.id
      );
      state.directorMibFiles = state.directorMibFiles?.filter(
        (el) => el?.id !== action.payload.id
      );
      state.application.directorMibFiles = newItems;
    },
    setUploadJustFile: (state, action) => {
      let items = [...(state.application.minjustFiles ?? [])];
      items.push(action.payload);
      state.application.minjustFiles = items;
    },
    setJustFiles: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.minjustFiles.push(action.payload.data);
    },
    removeJustFile: (
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      let newItems = state.application.minjustFiles?.filter(
        (el) => el !== action.payload.id
      );
      state.minjustFiles = state.minjustFiles?.filter(
        (el) => el?.id !== action.payload.id
      );
      state.application.minjustFiles = newItems;
    },
    setUploadTransFile: (state, action) => {
      let items = [...(state.application.mintransFiles ?? [])];
      items.push(action.payload);
      state.application.mintransFiles = items;
    },
    setTransFiles: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.mintransFiles.push(action.payload.data);
    },
    removeTransFile: (
      state,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      let newItems = state.application.mintransFiles?.filter(
        (el) => el !== action.payload.id
      );
      state.mintransFiles = state.mintransFiles?.filter(
        (el) => el?.id !== action.payload.id
      );
      state.application.mintransFiles = newItems;
    },
    clearSupplier: (state) => {
      state.application = {
        affiliations: [
          {
            tin: "",
            organizationName: "",
          },
        ],
        organizationName: "",
        organizationCadasters: [
          {
            number: "",
            info: "",
            ban: undefined,
            area: "",
          },
        ],
        directorCadasters: [
          {
            number: "",
            info: "",
            ban: undefined,
            area: "",
          },
        ],
        founders: [
          {
            name: "",
            percent: "",
            inn: "",
          },
        ],
        account: "",
        accountantName: "",
        activity: "",
        address: "",
        bank: "",
        activityStatus: "",
        mfo: "",
        opf: "",
        directorMibDebt: "",
        directorName: "",
        directorPhone: "",
        directorPinfl: "",
        email: "",
        directorTin: "",
        isNdsPayer: undefined,
        oked: "",
        largeTaxPayer: undefined,
        ndsRegDate: "",
        licenseOfmintrans: undefined,
        ndsStatus: "",
        licenseOfMinyust: undefined,
        ndsRegNumber: "",
        obnalStatus: undefined,
        organizationMibDebt: "",
        ratingDesignOrganizations: "",
        ratingGeneralConstruction: "",
        ratingHighwaysAndBridges: "",
        ratingReclamationAndIrrigation: "",
        registerDate: "",
        registerNumber: "",
        reorganization: undefined,
        soogu: "",
        taxDebt: "",
        taxGap: "",
        ustavCapital: "",
        ratingCategory: "",
        ratingBall: "",
        liquidation: undefined,
        isEconomicSud: undefined,
        sudFiles: [],
        minjustFiles: [],
        mintransFiles: [],
        mibFiles: [],
        directorMibFiles: [],
        sudMonitorings: [
          {
            result: "",
            casenumber: "",
            claimant: "",
            defendant: "",
            reg_date: "",
            hearing_date: "",
          },
        ],
      };
    },
  },
  extraReducers: {
    [getAllCheckedSuppliers.pending.type]: (state: any) => {
      state.status = "pending";
      state.loading = true;
    },
    [getAllCheckedSuppliers.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.loading = false;
      state.suppliers = payload.content;
      state.totalCount = payload.totalElements;
    },
    [getAllCheckedSuppliers.rejected.type]: (state: any, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [getOneCheckedSuppliers.pending.type]: (state: any) => {
      state.status = "pending";
      state.loading = true;
    },
    [getOneCheckedSuppliers.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.supplier = payload;
      state.application = payload;
      if (state.application.founders && state.application.founders.length < 1) {
        state.application.founders = [
          ...(state.application.founders ?? []),
          {
            name: "",
            percent: "",
            inn: "",
          },
        ];
      }
      if (
        state.application.directorCadasters &&
        state.application.directorCadasters.length < 1
      ) {
        state.application.directorCadasters = [
          ...(state.application.directorCadasters ?? []),
          {
            number: "",
            ban: undefined,
            area: "",
            info: "",
          },
        ];
      }
      if (
        state.application.organizationCadasters &&
        state.application.organizationCadasters.length < 1
      ) {
        state.application.organizationCadasters = [
          ...(state.application.organizationCadasters ?? []),
          {
            number: "",
            ban: undefined,
            area: "",
            info: "",
          },
        ];
      }
      if (
        state.application.affiliations &&
        state.application.affiliations.length < 1
      ) {
        state.application.affiliations = [
          ...(state.supplier.affiliations ?? []),
          {
            tin: "",
            organizationName: "",
          },
        ];
      }
      let sudFiles = payload?.sudFiles?.map((file: any) => file?.id);
      let mibFiles = payload?.mibFiles?.map((file: any) => file?.id);
      let directorMibFiles = payload?.directorMibFiles?.map(
        (file: any) => file?.id
      );
      let justMiles = payload?.minjustFiles?.map((file: any) => file?.id);
      let transFiles = payload?.mintransFiles?.map((file: any) => file?.id);
      state.sudFiles = payload?.sudFiles || [];
      state.supplier.sudFiles = sudFiles || [];
      state.mibFiles = payload?.mibFiles || [];
      state.supplier.mibFiles = mibFiles || [];
      state.directorMibFiles = payload?.directorMibFiles || [];
      state.supplier.directorMibFiles = directorMibFiles || [];
      state.minjustFiles = payload?.minjustFiles || [];
      state.supplier.minjustFiles = justMiles || [];
      state.mintransFiles = payload?.mintransFiles || [];
      state.supplier.mintransFiles = transFiles || [];
      state.application.lastCheckGNK = payload?.lastCheckGNK?.split(',')
      state.loading = false;
    },
    [getOneCheckedSuppliers.rejected.type]: (state, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [getDubiousOrganizations.pending.type]: (state: any) => {
      state.status = "pending";
      state.dbLoader = true;
    },
    [getDubiousOrganizations.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.dubiousOrganizations = payload.content;
      state.dubiousTotalCount = payload.totalElements;
      state.dbLoader = false;
    },
    [getDubiousOrganizations.rejected.type]: (state: any, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.dbLoader = false;
    },
    [getUnscrupulousPerformers.pending.type]: (state: any) => {
      state.status = "pending";
      state.loading = true;
    },
    [getUnscrupulousPerformers.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.unscrupulousPerformers = payload.content;
      state.unscrupulousTotal = payload.totalElements;
      state.loading = false;
    },
    [getUnscrupulousPerformers.rejected.type]: (state: any, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [getSupplierInfo.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [getSupplierInfo.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.supplier = payload;
      state.loading = false;
    },
    [getSupplierInfo.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [getCaptcha.pending.type]: (state) => {
      state.status = "pending";
      state.captchaLoad = true;
    },
    [getCaptcha.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.captcha = payload;
      state.captchaLoad = false;
    },
    [getCaptcha.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.captchaLoad = false;
    },
    [createApplication.pending.type]: (state: any) => {
      state.status = "pending";
      state.sendLoader = true;
    },
    [createApplication.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.create = payload;
      state.sendLoader = false;
    },
    [createApplication.rejected.type]: (state, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.sendLoader = false;
    },
    [createApp.pending.type]: (state: any) => {
      state.sendLoader = true;
    },
    [createApp.fulfilled.type]: (state, { payload }: any) => {
      state.create = payload;
      state.sendLoader = false;
    },
    [createApp.rejected.type]: (state, action: any) => {
      state.error = action.message;
      state.sendLoader = false;
    },
    [completeApplication.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [completeApplication.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.complete = payload;
      state.loading = false;
    },
    [completeApplication.rejected.type]: (state, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [updateApplication.pending.type]: (state: any) => {
      state.status = "pending";
      state.loading = true;
    },
    [updateApplication.fulfilled.type]: (state, { payload }: any) => {
      state.status = "success";
      state.update = payload;
      state.loading = false;
    },
    [updateApplication.rejected.type]: (state, action: any) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
  },
});

export const {
  setSupplier,
  setFounder,
  setDirObject,
  setOrgObject,
  setAffiliation,
  removeFounder,
  addFounder,
  addOrgObject,
  removeOrgObject,
  removeDirObject,
  addDirObject,
  removeAffiliation,
  addAffiliation,
  removeCourtMonitoring,
  setCourtMonitoring,
  addCourtMonitoring,
  setUploadSudFile,
  setSudFiles,
  removeSudFile,
  clearSupplier,
  removeNibFile,
  removeTransFile,
  setJustFiles,
  setMibFiles,
  setTransFiles,
  setUploadJustFile,
  setUploadMibFile,
  setUploadTransFile,
  removeJustFile,
  setDirectorMibFiles,
  setUploadDirectorMibFile,
  removeDirectorNibFile,
  addOrganizationMibDebtorDocuments,
  removeOrganizationMibCreditorDocuments,
  addOrganizationMibCreditorDocuments,
  removeOrganizationMibDebtorDocuments,
  removeOrganizationMinjusts,
  addOrganizationMinjusts,
  setOrganizationMibDebtorDocuments,
  setOrganizationMinjusts,
  setOrganizationMibCreditorDocuments
} = supplierSlice.actions;
export default supplierSlice.reducer;
