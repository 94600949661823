/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DictionaryDTO } from '../models/DictionaryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DictionaryControllerService {

    /**
     * getApplicationStatusType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getApplicationStatusTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/application-status-type',
        });
    }

    /**
     * getCurrencyType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getCurrencyTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/currency-type',
        });
    }

    /**
     * getLanguageType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getLanguageTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/language-type',
        });
    }

    /**
     * getOrganizationStatusType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getOrganizationStatusTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/organization-status-type',
        });
    }

    /**
     * getRoleType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getRoleTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/role-type',
        });
    }

    /**
     * getTariffType
     * @returns DictionaryDTO OK
     * @throws ApiError
     */
    public static getTariffTypeUsingGet(): CancelablePromise<Array<DictionaryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dictionary/tariff-type',
        });
    }

}
