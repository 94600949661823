import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {clearMessage, setMessage} from "../../../redux/slices/message";
import {createMessage, getAllMessages, getOneMessage, updateMessage} from "../../../redux/services/messageService";
import {MessageCreateUpdateDTO} from "../../../services/openapi";
import {toast} from "react-toastify";
import {SVG} from "../../core/SVG";
import {EditIcon} from "../../Icons";
import {Modal, Spin} from "antd";
import InputGroup from "../../core/InputGroup";

type Props = {
    id?: number;
    page?: number;
    size?: number;
};
const CreateUpdateMessage: React.FC<Props> = ({ id,page,size }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { loading, message } = useAppSelector((state) => state.message);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setMessage({
                name: event.target.name,
                value: event.target.value,
            })
        );
    };

    const handleOpen = () => {
        setOpen(true);
        if (id) {
            console.log('id')
            dispatch(getOneMessage(id));
        }
    };
    const handleClose = () => {
        setOpen(false);
        dispatch(clearMessage());
    };

    const handleSubmit = async () => {
        try {
            let res: any = await dispatch(
                id
                    ? updateMessage(message as MessageCreateUpdateDTO)
                    : createMessage(message as MessageCreateUpdateDTO)
            );
            if (res.payload) {
                if (res.payload.success) {
                    toast(res.payload?.message, {
                        type: "success",
                    });
                    handleClose();
                    dispatch(getAllMessages({page,size}));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <button
                className={`btn ${
                    id
                        ? "btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
                        : "btn-sm btn-primary"
                }`}
                onClick={handleOpen}
            >
                {id ? <SVG icon={<EditIcon />} className="svg-icon-2" /> : t("create")}
            </button>
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        {t(`${id ? "updateMessage" : "createMessage"}`)}
                        {loading ? <Spin className="d-flex ms-2" size="small" /> : ""}
                    </div>
                }
                open={open}
                onCancel={handleClose}
                cancelText={t("cancel")}
                onOk={handleSubmit}
                okText={t("save")}
            >
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label={t("key")}
                            type="text"
                            name="key"
                            required
                            value={message.key}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                            label="EN"
                            type="text"
                            name="textEn"
                            required
                            value={message.textEn}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <InputGroup
                            label="РУ"
                            type="text"
                            name="textRu"
                            required
                            value={message.textRu}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="col">
                        <InputGroup
                            label="O'Z"
                            type="text"
                            name="textUz"
                            required
                            value={message.textUz}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateUpdateMessage;