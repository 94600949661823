import {createSlice} from "@reduxjs/toolkit";
import {DictionaryDTO} from "../../services/openapi";
import {getCurrencyType} from "../services/dictionaryService";

interface MessageState {
  currency: DictionaryDTO[];
  loading: boolean;
  status: string;
  error: string;
}

const initialState: MessageState = {
  currency: [],
  loading: false,
  status: "",
  error: ""
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers:{},
  extraReducers: {
    [getCurrencyType.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [getCurrencyType.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.currency = payload;
      state.loading = false;
    },
    [getCurrencyType.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
  }
});
export default dictionarySlice.reducer;
