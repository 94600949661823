/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfPageOfPermissionDTO } from '../models/ApiResultOfPageOfPermissionDTO';
import type { ApiResultOfPermissionDTO } from '../models/ApiResultOfPermissionDTO';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { PermissionCreateUpdateDTO } from '../models/PermissionCreateUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PermissionControllerService {

    /**
     * create
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static createUsingPost2(
        dto: PermissionCreateUpdateDTO,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/permission',
            body: dto,
        });
    }

    /**
     * update
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static updateUsingPut2(
        dto: PermissionCreateUpdateDTO,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/permission',
            body: dto,
        });
    }

    /**
     * getAll
     * @param page page
     * @param size size
     * @returns ApiResultOfPageOfPermissionDTO OK
     * @throws ApiError
     */
    public static getAllUsingGet3(
        page?: number,
        size: number = 20,
    ): CancelablePromise<ApiResultOfPageOfPermissionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/permission/get-all',
            query: {
                'page': page,
                'size': size,
            },
        });
    }

    /**
     * get
     * @param id id
     * @returns ApiResultOfPermissionDTO OK
     * @throws ApiError
     */
    public static getUsingGet3(
        id: number,
    ): CancelablePromise<ApiResultOfPermissionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/permission/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete
     * @param id id
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static deleteUsingDelete2(
        id: number,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/permission/{id}',
            path: {
                'id': id,
            },
        });
    }

}
