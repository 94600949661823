import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import { IResidentsFilter} from "../../../models";
import {PaginationProps} from "antd";
import {fieldCheck} from "../../../helpers/fieldCheck";
import {getAllResidents} from "../../../redux/services/residents";
import {ColumnGroupType, ColumnType} from "antd/lib/table";
import { ResidentCompany} from "../../../services/openapi";
import Input from "../../core/Input";
import CustomTable from "../../core/CustomTable";
import {SVG} from "../../core/SVG";
import {DeleteIcon, SearchTableIcon} from "../../Icons";

const Residents = () => {
    const {t} = useTranslation()
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(25);
    const [filter, setFilter] = useState<IResidentsFilter>({
        inn: "",
        name: ""
    });

    const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
        setPage(pageNumber - 1);
    };
    const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
        current,
        pageSize
    ) => {
        setPage(current);
        setSize(pageSize);
    };
    const dispatch = useAppDispatch();

    const { residents, loading, totalCount, error } = useAppSelector(state => state.resident)

    useEffect(() => {
        dispatch(getAllResidents({ page, size }));
    }, [dispatch, page, size]);

    const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        setFilter((filter) => ({ ...filter, [name]: value }));
    };

    const handleFilter = async () => {
        await dispatch(
            getAllResidents({
                page: 0,
                size,
                ...fieldCheck(filter),
            })
        );
    };
    const handleClearFilter = async () => {
        setFilter({
            name: "",
            inn: ""
        });
        await dispatch(
            getAllResidents({
                page: 0,
                size,
            })
        );
    };

    const columns: (
        | ColumnGroupType<ResidentCompany>
        | ColumnType<ResidentCompany>
        )[] = [
        {
            title: "№",
            key: "id",
            width: "80px",
            className: "text-center gx-0",
            render: (row) => {
                let index = residents.indexOf(row);
                return page * size + index + 1;
            },
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("region")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100 d-flex">
                        <Input
                            type="text"
                            name="region"
                            value={undefined}
                            disabled
                        />
                    </div>
                </div>
            ),
            key: "region",
            render: (_, record) => record.region?.name
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("address")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100 d-flex">
                        <Input
                            type="text"
                            name="region"
                            value={undefined}
                            disabled
                        />
                    </div>
                </div>
            ),
            key: "address",
            render: (_, record) => <span>
                {record.district?.name} <br/>
                {record.address}
            </span>
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("name")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100 d-flex">
                        <Input
                            type="text"
                            name="name"
                            value={filter.name}
                            onChange={handleChangeFilter}
                        />
                    </div>
                </div>
            ),
            dataIndex: "name",
            key: "name",
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("company_type")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100 d-flex">
                        <Input
                            type="text"
                            name="company_type"
                            value={undefined}
                            disabled
                        />
                    </div>
                </div>
            ),
            key: "company_type",
            dataIndex: "company_type"
        },
        {
            title: (
                <div className="d-flex list-group">
                    <div className="mt-4 mb-2 text-start"> {t("tin")}</div>
                    <div className="fv-row mb-4 w-150px min-w-100 d-flex">
                        <Input
                            type="text"
                            name="inn"
                            value={filter.inn}
                            onChange={handleChangeFilter}
                        />
                        <div className="fv-row mb-4">
                            <div
                                onClick={handleClearFilter}
                                className="btn btn-icon btn-bg-light btn-active-color-primary w-25px h-30px mb-0 me-0"
                            >
                                <SVG icon={<DeleteIcon />} className="svg-icon-1" />
                            </div>
                        </div>
                        <div className="fv-row mb-4 me-1">
                            <div
                                onClick={handleFilter}
                                className="btn btn-icon btn-bg-light btn-active-color-primary w-25px h-30px  mb-0 me-0"
                            >
                                <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
                            </div>
                        </div>
                    </div>
                </div>
            ),
            dataIndex: "tin",
            key: "tin",
        }
    ];

    const data = useMemo(() => residents, [residents]);

    return (
        <>
            <Toolbar title={`${t("residents")} IT-Park`}  />
            <CardBody
                children={<>
                    <CustomTable
                        columns={columns}
                        error={error}
                        data={data}
                        loading={loading}
                        page={page}
                        pageSize={size}
                        className="table-striped-rows"
                        paginationShowChange={onShowSizeChange}
                        paginationChange={onChangePagination}
                        totalCount={totalCount}
                    />
                </>}
            />
        </>
    );
};

export default Residents;