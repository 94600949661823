/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDTO = {
    avatarId?: number;
    avatarUrl?: string;
    firstName?: string;
    id?: number;
    lang?: string;
    lastName?: string;
    middleName?: string;
    organizationId?: number;
    organizationName?: string;
    phoneNumber?: string;
    positionTypeId?: number;
    positionTypeName?: string;
    role?: string;
    roleId?: number;
    shortName?: string;
    status?: UserDTO.status;
};

export namespace UserDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        BLOCKED = 'BLOCKED',
    }


}

