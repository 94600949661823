/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationClientCreateUpdateDTO = {
    /**
     * Bu tashkilot faoiliyat turi
     */
    activity?: string;
    /**
     * Bu tashkilot manzili
     */
    address?: string;
    /**
     * Email faqat @ belgisi bilan kiritilishi kerak
     */
    email?: string;
    firstName?: string;
    /**
     * Bu tashkilot INN raqami, probel bo'lishi mumkin emas
     */
    inn: string;
    lastName?: string;
    middleName?: string;
    /**
     * Bu tashkilot nomi
     */
    name: string;
    /**
     * Phone number faqat +998 formatda bo'ladi va probel bo'lmasligi kerak
     */
    phone?: string;
    /**
     * Tashkilot sayti
     */
    site?: string;
    /**
     * Tariff type
     */
    tariffType?: OrganizationClientCreateUpdateDTO.tariffType;
    userPhone?: string;
};

export namespace OrganizationClientCreateUpdateDTO {

    /**
     * Tariff type
     */
    export enum tariffType {
        BRONZE = 'BRONZE',
        SILVER = 'SILVER',
        GOLD = 'GOLD',
        PLATINUM = 'PLATINUM',
    }


}

