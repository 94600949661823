import {RoleDTO} from "../../services/openapi";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createRole, getAllRoles, getOneRole, updateRole} from "../services/roleService";

interface RoleState {
    roles: RoleDTO[];
    role: RoleDTO;
    loading: boolean;
    status: string;
    error: string;
    response: any;
    totalCount: any
}

const initialState: RoleState = {
    roles: [],
    role: {},
    loading: false,
    status: "",
    error: "",
    response: {},
    totalCount: 0
};

export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        setRole: (state, action: PayloadAction<{ name: any; value: string }>) => {
            state.role = {
                ...state.role,
                [action.payload.name]: action.payload.value,
            };
        },
        clearRole: (state) => {
            state.role = {
                code: undefined,
            };
        },
    },
    extraReducers: {
        [getAllRoles.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getAllRoles.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.roles = payload.content;
            state.totalCount = payload.totalElements;
            state.loading = false;
        },
        [getAllRoles.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [createRole.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [createRole.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [createRole.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [getOneRole.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [getOneRole.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.role = payload;
            state.loading = false;
        },
        [getOneRole.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
        [updateRole.pending.type]: (state) => {
            state.status = "pending";
            state.loading = true;
        },
        [updateRole.fulfilled.type]: (state, { payload }) => {
            state.status = "success";
            state.response = payload;
            state.loading = false;
        },
        [updateRole.rejected.type]: (state, action) => {
            state.status = "failed";
            state.error = action.message;
            state.loading = false;
        },
    },
});

export const { setRole, clearRole } = roleSlice.actions;

export default roleSlice.reducer;
