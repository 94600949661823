import {
  Access,
  Check,
  CheckedSuppliers, Dubious, Messages,
  Organizations, Permissions, Profile, Roles, Services, Unscrupulous,
  Users,
} from "../pages";
import Error from "../pages/private/Egov/Error";
import Request from "../pages/private/Egov/Request";
import {Residents} from "../components";

export const ROUTES = [
  {
    path: "check",
    name: "Check",
    component: <Check />,
  },
  {
    path: "checked-suppliers",
    name: "Checked Suppliers",
    component: <CheckedSuppliers />,
  },
  {
    path: "residents",
    name: "Residents",
    component: <Residents />,
  },
  {
    path: "dubious-organizations",
    name: "Dubious Organizations",
    component: <Dubious />,
  },
  {
    path: "unscrupulous-performer",
    name: "Unscrupulous Performers",
    component: <Unscrupulous/>
  },
  {
    path: "organizations",
    name: "Organizations",
    component: <Organizations />,
  },
  {
    path: "users",
    name: "Users",
    component: <Users />,
  },
  {
    path: 'services',
    name: "Services",
    component: <Services/>
  },
  {
    path: "permissions",
    component: <Permissions />,
  },
  {
    path: "messages",
    component: <Messages />,
  },
  {
    path: "roles",
    component: <Roles />,
  },
  {
    path: "access",
    component: <Access />,
  },
  {
    path: "egov/error",
    component: <Error />,
  },
  {
    path: "egov/request",
    component: <Request />,
  },
  {
    path: "profile",
    name: "Profile",
    component: <Profile />,
  },
];
