import {createAsyncThunk} from "@reduxjs/toolkit";
import {PermissionControllerService, PermissionCreateUpdateDTO} from "../../services/openapi";
import {toast} from "react-toastify";

export const getAllPermissions = createAsyncThunk(
    "permission/get-all",
    async (args: { page?: any; size?: any }) => {
        try {
            const response = await PermissionControllerService.getAllUsingGet3(
                (args.page = args.page || 0),
                (args.size = args.size || 25)
            );
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const createPermission = createAsyncThunk(
    "permission/create",
    async (data: PermissionCreateUpdateDTO) => {
        try {
            return await PermissionControllerService.createUsingPost2(data);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const getOnePermission = createAsyncThunk(
    "permission/get-one",
    async (id: number) => {
        try {
            const response = await PermissionControllerService.getUsingGet3(id);
            return response.data;
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const updatePermission = createAsyncThunk(
    "permission/update",
    async (data: PermissionCreateUpdateDTO) => {
        try {
            return await PermissionControllerService.updateUsingPut2(data);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);

export const deletePermission = createAsyncThunk(
    "permission/delete",
    async (id: number) => {
        try {
            return await PermissionControllerService.deleteUsingDelete2(id);
        } catch (error: any) {
            toast(error?.body?.errors[0]?.errorMsg || error.message, {
                type: "error",
            });
        }
    }
);