/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationCreateUpdateDTO = {
    /**
     * Bu tashkilot faoiliyat turi
     */
    activity?: string;
    /**
     * Bu tashkilot manzili
     */
    address?: string;
    attemptCount?: number;
    /**
     * Email faqat @ belgisi bilan kiritilishi kerak
     */
    email?: string;
    expireDate?: string;
    /**
     * ID faqat update qilish uchun kerak
     */
    id?: number;
    /**
     * Bu tashkilot INN raqami, probel bo'lishi mumkin emas
     */
    inn: string;
    /**
     * Bu tashkilot nomi
     */
    name: string;
    /**
     * Phone number faqat +998 formatda bo'ladi va probel bo'lmasligi kerak
     */
    phone?: string;
    /**
     * Tashkilot sayti
     */
    site?: string;
    /**
     * Tariff type
     */
    tariffType?: OrganizationCreateUpdateDTO.tariffType;
};

export namespace OrganizationCreateUpdateDTO {

    /**
     * Tariff type
     */
    export enum tariffType {
        BRONZE = 'BRONZE',
        SILVER = 'SILVER',
        GOLD = 'GOLD',
        PLATINUM = 'PLATINUM',
    }


}

