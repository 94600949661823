/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResultOfBankInfoDTO } from '../models/ApiResultOfBankInfoDTO';
import type { ApiResultOfCaptchaBase64Img } from '../models/ApiResultOfCaptchaBase64Img';
import type { ApiResultOfLicenseRoot } from '../models/ApiResultOfLicenseRoot';
import type { ApiResultOfListOfCurrencyRateDTO } from '../models/ApiResultOfListOfCurrencyRateDTO';
import type { ApiResultOfMibRoot } from '../models/ApiResultOfMibRoot';
import type { ApiResultOfOkedInfoDTO } from '../models/ApiResultOfOkedInfoDTO';
import type { ApiResultOfPageOfEgovErrorResponseDTO } from '../models/ApiResultOfPageOfEgovErrorResponseDTO';
import type { ApiResultOfPageOfEgovRequestDTO } from '../models/ApiResultOfPageOfEgovRequestDTO';
import type { ApiResultOfPageOfObnalSupplierDto } from '../models/ApiResultOfPageOfObnalSupplierDto';
import type { ApiResultOfPageOfUnscrupulousPerformerDTO } from '../models/ApiResultOfPageOfUnscrupulousPerformerDTO';
import type { ApiResultOfResidentDTO } from '../models/ApiResultOfResidentDTO';
import type { ApiResultOfSbuCheckedSupplierDto } from '../models/ApiResultOfSbuCheckedSupplierDto';
import type { ApiResultOfSoliqInfoResponse } from '../models/ApiResultOfSoliqInfoResponse';
import type { ApiResultOfstring } from '../models/ApiResultOfstring';
import type { ApiResultOfTokenResponse } from '../models/ApiResultOfTokenResponse';
import type { CadastrRequestDTO } from '../models/CadastrRequestDTO';
import type { ContactDto } from '../models/ContactDto';
import type { DebtRequestDTO } from '../models/DebtRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RemoteControllerService {

    /**
     * getBankInfoByMfo
     * @param mfo mfo
     * @returns ApiResultOfBankInfoDTO OK
     * @throws ApiError
     */
    public static getBankInfoByMfoUsingGet(
        mfo: string,
    ): CancelablePromise<ApiResultOfBankInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/bank-info/{mfo}',
            path: {
                'mfo': mfo,
            },
        });
    }

    /**
     * cadastr
     * @param dto dto
     * @param type type
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static cadastrUsingPost(
        dto: CadastrRequestDTO,
        type: 'BY_TIN' | 'BY_PINFL' | 'BY_CAD_NUMBER' | 'BY_PASSPORT',
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/remote/cadastr',
            query: {
                'type': type,
            },
            body: dto,
        });
    }

    /**
     * getOwsCaptcha
     * @returns ApiResultOfCaptchaBase64Img OK
     * @throws ApiError
     */
    public static getOwsCaptchaUsingGet(): CancelablePromise<ApiResultOfCaptchaBase64Img> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/captcha',
        });
    }

    /**
     * getCbuInfo
     * @returns ApiResultOfListOfCurrencyRateDTO OK
     * @throws ApiError
     */
    public static getCbuInfoUsingGet(): CancelablePromise<ApiResultOfListOfCurrencyRateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/cbu',
        });
    }

    /**
     * contact
     * @param dto dto
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static contactUsingPost(
        dto: ContactDto,
    ): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/remote/contact',
            body: dto,
        });
    }

    /**
     * getContragentInfo
     * @param inn inn
     * @returns ApiResultOfSoliqInfoResponse OK
     * @throws ApiError
     */
    public static getContragentInfoUsingGet(
        inn: string,
    ): CancelablePromise<ApiResultOfSoliqInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/contragent/{inn}',
            path: {
                'inn': inn,
            },
        });
    }

    /**
     * debt
     * @param dto dto
     * @param type type
     * @returns ApiResultOfMibRoot OK
     * @throws ApiError
     */
    public static debtUsingPost(
        dto: DebtRequestDTO,
        type: 'BY_TIN' | 'BY_PINFL' | 'BY_CAD_NUMBER' | 'BY_PASSPORT',
    ): CancelablePromise<ApiResultOfMibRoot> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/remote/debt',
            query: {
                'type': type,
            },
            body: dto,
        });
    }

    /**
     * getAllErrorResponses
     * @param page page
     * @param size size
     * @param url
     * @returns ApiResultOfPageOfEgovErrorResponseDTO OK
     * @throws ApiError
     */
    public static getAllErrorResponsesUsingGet(
        page?: number,
        size: number = 25,
        url?: string,
    ): CancelablePromise<ApiResultOfPageOfEgovErrorResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/egov/error/response',
            query: {
                'page': page,
                'size': size,
                'url': url,
            },
        });
    }

    /**
     * getAllRequests
     * @param page page
     * @param size size
     * @param url
     * @returns ApiResultOfPageOfEgovRequestDTO OK
     * @throws ApiError
     */
    public static getAllRequestsUsingGet(
        page?: number,
        size: number = 25,
        url?: string,
    ): CancelablePromise<ApiResultOfPageOfEgovRequestDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/egov/request',
            query: {
                'page': page,
                'size': size,
                'url': url,
            },
        });
    }

    /**
     * getLicensePinfl
     * @param pinfl pinfl
     * @returns ApiResultOfLicenseRoot OK
     * @throws ApiError
     */
    public static getLicensePinflUsingGet(
        pinfl: string,
    ): CancelablePromise<ApiResultOfLicenseRoot> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/license/pinfl/{pinfl}',
            path: {
                'pinfl': pinfl,
            },
        });
    }

    /**
     * getLicenseTin
     * @param tin tin
     * @returns ApiResultOfLicenseRoot OK
     * @throws ApiError
     */
    public static getLicenseTinUsingGet(
        tin: string,
    ): CancelablePromise<ApiResultOfLicenseRoot> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/license/tin/{tin}',
            path: {
                'tin': tin,
            },
        });
    }

    /**
     * getObnalSupplier
     * @param city
     * @param founderName
     * @param inn
     * @param organizationName
     * @param page page
     * @param registerDate
     * @param size size
     * @returns ApiResultOfPageOfObnalSupplierDto OK
     * @throws ApiError
     */
    public static getObnalSupplierUsingGet(
        city?: string,
        founderName?: string,
        inn?: string,
        organizationName?: string,
        page?: number,
        registerDate?: string,
        size: number = 25,
    ): CancelablePromise<ApiResultOfPageOfObnalSupplierDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/obnal',
            query: {
                'city': city,
                'founderName': founderName,
                'inn': inn,
                'organizationName': organizationName,
                'page': page,
                'registerDate': registerDate,
                'size': size,
            },
        });
    }

    /**
     * getOkedInfoByCode
     * @param code code
     * @returns ApiResultOfOkedInfoDTO OK
     * @throws ApiError
     */
    public static getOkedInfoByCodeUsingGet(
        code: string,
    ): CancelablePromise<ApiResultOfOkedInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/oked-info/{code}',
            path: {
                'code': code,
            },
        });
    }

    /**
     * getOwsInfo
     * @param captcha captcha
     * @param captchaId captchaId
     * @param inn inn
     * @param jSessionId jSessionId
     * @returns ApiResultOfSbuCheckedSupplierDto OK
     * @throws ApiError
     */
    public static getOwsInfoUsingGet(
        captcha?: string,
        captchaId?: string,
        inn?: string,
        jSessionId?: string,
    ): CancelablePromise<ApiResultOfSbuCheckedSupplierDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/ows/info',
            query: {
                'captcha': captcha,
                'captchaId': captchaId,
                'inn': inn,
                'jSessionId': jSessionId,
            },
        });
    }

    /**
     * getAllResident
     * @param inn inn
     * @param name name
     * @param page page
     * @param size size
     * @returns ApiResultOfResidentDTO OK
     * @throws ApiError
     */
    public static getAllResidentUsingGet(
        inn?: string,
        name?: string,
        page: number = 1,
        size: number = 20,
    ): CancelablePromise<ApiResultOfResidentDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/resident/list',
            query: {
                'inn': inn,
                'name': name,
                'page': page,
                'size': size,
            },
        });
    }

    /**
     * test
     * @returns ApiResultOfstring OK
     * @throws ApiError
     */
    public static testUsingGet(): CancelablePromise<ApiResultOfstring> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/test',
        });
    }

    /**
     * getEgovToken
     * @returns ApiResultOfTokenResponse OK
     * @throws ApiError
     */
    public static getEgovTokenUsingGet1(): CancelablePromise<ApiResultOfTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/token',
        });
    }

    /**
     * getUnscrupulousPerformer
     * @param organizationName
     * @param page page
     * @param size size
     * @param tin
     * @returns ApiResultOfPageOfUnscrupulousPerformerDTO OK
     * @throws ApiError
     */
    public static getUnscrupulousPerformerUsingGet(
        organizationName?: string,
        page?: number,
        size: number = 25,
        tin?: string,
    ): CancelablePromise<ApiResultOfPageOfUnscrupulousPerformerDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/unscrupulous-performer',
            query: {
                'organizationName': organizationName,
                'page': page,
                'size': size,
                'tin': tin,
            },
        });
    }

    /**
     * updateEgovToken
     * @returns ApiResultOfTokenResponse OK
     * @throws ApiError
     */
    public static updateEgovTokenUsingGet(): CancelablePromise<ApiResultOfTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/remote/update-token',
        });
    }

}
