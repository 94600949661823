import React from "react";

type Props = {
    children?: JSX.Element;
};

const CardBody: React.FC<Props> = ({children}) => {
    return <div className="container-fluid" id="kt_content_container">
        <div className="card mb-5 mb-xl-8">
            <div className="card-body py-3 px-3">{children}</div>
        </div>
    </div>;
};

export default CardBody;
