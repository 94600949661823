import {OrganizationCreateUpdateDTO, OrganizationDTO} from "../../services/openapi";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  createOrganization,
  getAllOrganizations,
  getOneOrganization,
  updateOrganization
} from "../services/organizationService";

interface OrganizationState {
  organizations: OrganizationDTO[];
  organization: OrganizationCreateUpdateDTO;
  loading: boolean;
  status: string;
  error: string;
  response: any;
  totalCount: any
}

const initialState: OrganizationState = {
  organizations: [],
  organization: {
    inn: "",
    name: ""
  },
  loading: false,
  status: "",
  error: "",
  response: {},
  totalCount: 0
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (
        state,
        action: PayloadAction<{ name: any; value: string }>
    ) => {
      state.organization = {
        ...state.organization,
        [action.payload.name]: action.payload.value,
      };
    },
    clearOrganization: (state) => {
      state.organization = {
        address: "",
        email: "",
        inn: "",
        name: "",
        phone: "",
      };
    },
  },
  extraReducers: {
    [getAllOrganizations.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [getAllOrganizations.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.organizations = payload.content;
      state.totalCount = payload.totalElements;
      state.loading = false;
    },
    [getAllOrganizations.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [createOrganization.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [createOrganization.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.response = payload;
      state.loading = false;
    },
    [createOrganization.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [getOneOrganization.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [getOneOrganization.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.organization = payload;
      state.loading = false;
    },
    [getOneOrganization.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
    [updateOrganization.pending.type]: (state) => {
      state.status = "pending";
      state.loading = true;
    },
    [updateOrganization.fulfilled.type]: (state, { payload }) => {
      state.status = "success";
      state.response = payload;
      state.loading = false;
    },
    [updateOrganization.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.message;
      state.loading = false;
    },
  },
});

export const { setOrganization, clearOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
