import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../core/Modal";
import {
  CheckedSupplierSudMonitoringDto,
  ObnalSupplierDto,
} from "../../../services/openapi";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { PaginationProps } from "antd";
import CustomTable from "../../core/CustomTable";

type Props = {
  data: CheckedSupplierSudMonitoringDto[];
};
const CourtMonitoring: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };

  const columns: (
    | ColumnGroupType<ObnalSupplierDto>
    | ColumnType<ObnalSupplierDto>
  )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = data.indexOf(row);
        return index + 1;
      },
    },
    {
      title: t("registerDate"),
      dataIndex: "reg_date",
      key: "reg_date",
    },
    {
      title: t("casenumber"),
      dataIndex: "casenumber",
      key: "casenumber",
    },
    {
      title: t("hearingDate"),
      dataIndex: "hearing_date",
      key: "hearing_date",
    },
    {
      title: t("claimant"),
      dataIndex: "claimant",
      key: "claimant",
    },
    {
      title: t("defendant"),
      dataIndex: "defendant",
      key: "defendant",
    },
    {
      title: t("courtResult"),
      dataIndex: "result",
      key: "result",
    },
  ];
  return (
    <CustomModal
      title={t("courtMonitoring")}
      hiddenOK={true}
      btnTitle={t("show")}
      width={1400}
      btnClass="btn-sm btn-primary"
      children={
        <CustomTable
          columns={columns}
          data={data}
          page={page}
          pageSize={size}
          loading={false}
          className="table-striped-rows"
          paginationShowChange={onShowSizeChange}
          paginationChange={onChangePagination}
          totalCount={data.length}
        />
      }
    />
  );
};

export default CourtMonitoring;
