/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrganizationDTO } from './OrganizationDTO';
import type { PermissionDTO } from './PermissionDTO';

export type UserDetailsDTO = {
    attemptCount?: number;
    avatarId?: number;
    avatarUrl?: string;
    expireDate?: string;
    firstName?: string;
    id?: number;
    lang?: string;
    lastName?: string;
    middleName?: string;
    organization?: OrganizationDTO;
    permissions?: Array<PermissionDTO>;
    phoneNumber?: string;
    planCount?: string;
    positionType?: string;
    role?: string;
    roleId?: number;
    status?: UserDetailsDTO.status;
};

export namespace UserDetailsDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        BLOCKED = 'BLOCKED',
    }


}

